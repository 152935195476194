import FormSubHeading from "../components/FormSubHeading";
import Dashboard from "../Layout/Dashboard";
import { useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "primereact/button";
import "./MedicalCenters.css";
import { useParams } from "react-router-dom";
import { updateMedicalCenterRequirement, deleteMedicalCenterRequirement } from "../services/medicalCenters";
import CenterRequirementsTable from "./CenterRequirementsTable";
import RequirementModal from "./CenterRequirementModal";
import { Toast } from "primereact/toast";
import { useMedicalCentersRequirements, useSingleMedicalCenter } from "../hooks/queries";

const INITIAL_DATA = {
  client: null,
  requirement: null,
  requirement_name: "",
  type_requirement_name: "",
  days: [],
  price_ref: 0,
};

/**
 * Form to create new medical centers
 */
export default function EditMedicalCenter() {

  const params = useParams();
  const queryClient = useQueryClient();

  // State for form
  // const [slots, setSlots] = useState([]);
  const [initialData, setInitialData] = useState(INITIAL_DATA);

  // For modal
  const [showModal, setShowModal] = useState(false);
  const [shouldFilterReq, setShouldFilterReq] = useState(true);

  const toast = useRef(null);

  // Get medical center name
  const centerQuery = useSingleMedicalCenter(params.centerId);

  // Get requirements from medical center
  const centerRequirementsQuery = useMedicalCentersRequirements(params.centerId);

  const autoSaveChanges = useMutation({
    mutationFn: updateMedicalCenterRequirement,
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        toast.current.show({ severity: "success", summary: "Éxito", detail: message });
        setShowModal(false);
        setInitialData(INITIAL_DATA);
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: ["medicalCenter", "requirements", Number(params.centerId)],
          });
        }, 1000);
      } else {
        toast.current.show({ severity: "error", summary: "Error", detail: message });
      }
    }
  });

  const deleteServiceMutation = useMutation({
    mutationFn: deleteMedicalCenterRequirement,
    onSuccess: ({ type, message }) => {
      console.log("Onsucess:", type, message);
      if (type === "success") {
        // Refetch
        // queryClient.invalidateQueries({
        //   queryKey: ["servicio", params.centerId],
        // });
        // setInvoice(null);
        // Show message
        centerRequirementsQuery.refetch();
        toast.current?.show({ severity: "success", summary: "Servicio eliminado", detail: message });
      } else {
        toast.current?.show({ severity: "error", summary: "Error", detail: message });
      }
    }
  });

  /**
   * Buttons to edit a requirement and add a requirement
   */
  const AddAndEditBtns = () => (
    <div className="btn-container">
      <Button
        label="Agregar servicio"
        icon="pi pi-plus"
        type="button"
        onClick={() => {
          setInitialData(INITIAL_DATA);
          setShouldFilterReq(true);
          setShowModal(true);
        }}
      />
      {/* <Button
        label="Guardar cambios"
        icon="pi pi-save"
        severity="success"
        type="button"
        disabled={!canSave}
      /> */}
    </div>
  );

  return (
    <Dashboard headerTitle="Editar Proveedor" backButtonUrl="/proveedores" fixedWidth="900px">
      <Toast ref={toast} position="top-center" />

      <form className="p-fluid">
        <div className="pb-3">
          <FormSubHeading title="Datos del proveedor"/>
          <p className="text-lg"><b>Nombre del proveedor:</b> {centerQuery.data?.name ?? ""}</p>
        </div>
        <div className="pb-3 center-requirement-config">
          <FormSubHeading title="Disponibilidad de servicios" />
          <AddAndEditBtns />

          <CenterRequirementsTable
            value={centerRequirementsQuery.data ?? []}
            loading={centerRequirementsQuery.isLoading}
            onClickEdit={(row) => {
              return () => {
                setInitialData(row);
                setShouldFilterReq(false);
                setShowModal(true);
              }
            }}
            onClickDelete={(row) => {
              return () => {
                console.log(row);
                // Eliminar del servicio
                deleteServiceMutation.mutate(row.id)
              }
            }}
          />

          <AddAndEditBtns />
          <RequirementModal
            show={showModal}
            onHide={() => {
              if (showModal) setShowModal(false);
            }}
            saveData={(slot) => {
              // Save on backend
              autoSaveChanges.mutate({ ...slot, centerId: params.centerId, });
            }}
            currentCenterRequirements={centerRequirementsQuery.data ?? []}
            shouldFilterRequirements={shouldFilterReq}
            formState={initialData}
            setFormState={setInitialData}
          />
        </div>
      </form>
    </Dashboard>
  );
}
