import FormSubHeading from "../components/FormSubHeading";
import Dashboard from "../Layout/Dashboard";
import { useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { useParams } from "react-router-dom";
import { editScale, getSingleScale, updateScaleData } from "../services/baremos";
import { useClients, useRequirements } from "../hooks/queries";
import { Toast } from "primereact/toast";
import BaremoRequirementsTable from "./BaremoRequirementsTable";
import RequirementModal from "./BaremoRequirementModal";
import { MultiSelect } from "primereact/multiselect";
import { getClientsOfScale } from "../services/clients";
import { InputText } from "primereact/inputtext";

/**
 * Form to view and edit scale
 */
export default function EditBaremo() {

  const params = useParams();
  const [requirements, setRequirements] = useState([]);
  const [scaleClients, setScaleClients] = useState([]);
  const [canUpdateScaleData, setCanUpdateScaleData] = useState(false);
  const [name, setName] = useState("");

  // Get clients
  const clientsWithoutScale = useClients(true);
  const clientsOfScale = useQuery({
    queryKey: ["clients", { scaleId: Number(params.baremoId) }],
    queryFn: () => 
      getClientsOfScale(Number(params.baremoId))
      .then((data) => {
        setScaleClients(data);
        return data;
      }),
  });

  // Get data for baremo
  const scaleQuery = useQuery({
    queryKey: ["scales", params.baremoId],
    queryFn: async () => {
      return getSingleScale(params.baremoId)
      .then(({ name, scale_requirement }) => {
        setRequirements(scale_requirement.map((req) => ({
          id: req.id,
          requirement: req.requirement,
          price: req.price,
          action: "none",
          name: req.requirement_name,
          typeName: req.requirement_type,
        })));
        setName(name);
        return { name };
      })
    },
  });

  const canSave = requirements.length && requirements.every((req) => req.requirement && req.price);

  // Available requirements for adding
  const requirementsQuery = useRequirements();

  // State for form
  const [initialData, setInitialData] = useState({
    requirement: null,
    price: "",
    action: "create",
  });
  const [disableRequirementDropdown, setDisableRequirementDropdown] = useState(false);

  const toast = useRef(null);

  const queryClient = useQueryClient();

  // Update scale data
  const updateDataMutation = useMutation({
    mutationFn: updateScaleData,
    onSuccess: ({ type, message }) => {
      if (type === "error") {
        toast.current.show({ severity: "error", summary: "Error", detail: message });
      } else {
        toast.current.show({ severity: "success", summary: "Éxito", detail: message });
        queryClient.invalidateQueries({
          queryKey: ["scales", params.baremoId],
        });
        setCanUpdateScaleData(false);
      }
    }
  })

  // Edit scale
  const editRequirementsMutation = useMutation({
    mutationFn: editScale,
    onSuccess: ({ type, message }) => {
      if (type === "error") {
        toast.current.show({ severity: "error", summary: "Error", detail: message });
      } else {
        toast.current.show({ severity: "success", summary: "Éxito", detail: message });
        queryClient.invalidateQueries({
          queryKey: ["scales", params.baremoId],
        });
      }
    }
  });

  // For modal
  const [showModal, setShowModal] = useState(false);

  /**
   * Add requirement and save changes buttons
   */
  const AddAndSaveBtns = () => (
    <div className="btn-container">
      <Button
        label="Agregar servicio"
        icon="pi pi-plus"
        type="button"
        onClick={() => {
          setInitialData({
            requirement: null,
            price: "",
            action: "create",
          });
          setShowModal(true);
          setDisableRequirementDropdown(false);
        }}
      />
      <Button
        label="Guardar cambios"
        icon="pi pi-save"
        severity="success"
        type="button"
        disabled={!canSave || editRequirementsMutation.isPending}
        loading={editRequirementsMutation.isPending}
        onClick={() => {
          editRequirementsMutation.mutate({ scaleId: params.baremoId, requirements });
        }}
      />
    </div>
  );

  return (
    <Dashboard headerTitle="Editar Baremo" backButtonUrl="/baremos" fixedWidth="850px">
      <Toast ref={toast} position="top-center" />

      <form className="p-fluid">

        <div className="pb-1">
          <FormSubHeading title="Datos del baremo"/>
          <div className="p-field">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (!canUpdateScaleData) setCanUpdateScaleData(true);
              }}
            />
          </div>
          <div className="p-field">
            <label htmlFor="clients">Cliente</label>
            <MultiSelect
              value={scaleClients}
              onChange={(e) => {
                setScaleClients(e.value);
                if (!canUpdateScaleData) setCanUpdateScaleData(true);
              }}
              options={[
                ...(clientsWithoutScale.data ?? []),
                ...(clientsOfScale.data ?? []),
              ]}
              dataKey="id"
              optionLabel="name"
              emptyMessage="No hay clientes disponibles"
            />
          </div>
          {canUpdateScaleData && (
            <Button
              type="button"
              icon="pi pi-save"
              severity="success"
              label="Actualizar baremo"
              className="mt-1"
              style={{ maxWidth: "fit-content" }}
              disabled={updateDataMutation.isPending}
              loading={updateDataMutation.isPending}
              onClick={() => {
                updateDataMutation.mutate({
                  scaleId: Number(params.baremoId),
                  name,
                  clients: scaleClients.map((client) => client.id),
                });
              }}
            />
          )}
        </div>

        <div className="pb-1 center-requirement-config">
          <FormSubHeading title="Servicios" />
          {/* <AddAndSaveBtns /> */}

          <BaremoRequirementsTable
            value={requirements}
            loading={scaleQuery.isLoading}
            onClickEdit={(row) => {
              return () => {
                const newInitialData = { ...row, action: "update" };
                if (row.action === "create") {
                  newInitialData.action = "create";
                }
                setInitialData(newInitialData);
                setShowModal(true);
                setDisableRequirementDropdown(true);
              }
            }}
            onClickDelete={(row) => {
              return () => {
                const newRequirements = requirements.filter((req) => req.requirement !== row.requirement);
                setRequirements(newRequirements);
              }
            }}
          />

          <RequirementModal
            show={showModal}
            onHide={() => {
              if (showModal) setShowModal(false);
              if (initialData) setInitialData({
                requirement: null,
                price: "",
              });
              setDisableRequirementDropdown(false);
            }}
            saveData={(req) => {
              const newReq = requirements.filter((r) => r.requirement !== req.requirement);
              setRequirements([...newReq, req]);
              setInitialData({
                requirement: null,
                price: "",
                action: "create",
              });
              setDisableRequirementDropdown(false);
              setShowModal(false);
            }}
            requirementsQuery={requirementsQuery}
            formState={initialData}
            setFormState={setInitialData}
            requirementsFilter={(req) => !requirements.find((r) => r.requirement === req.id)}
            disableDropdown={disableRequirementDropdown}
          />

          <AddAndSaveBtns />
        </div>
      </form>
    </Dashboard>
  );
}
