import React from 'react';
import Dashboard from "../Layout/Dashboard";
import CreateCase from './CreateCase';

function MyComponent() {
    return (
        <Dashboard headerTitle="Nuevo caso" fixedWidth='600px'>
            <CreateCase></CreateCase>
        </Dashboard>        
    );
}

export default MyComponent;