import { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import FormSubHeading from "../components/FormSubHeading";
import "./CreateCase.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeleteButton, EditButton } from "../components/TableButtons";
import { GENDERS, MONTHS, PHONE_CODES } from "../constants";
import { addCase } from "../services/medicalCases";
import { getHoldersMatchingCI, getPeopleMatchingCI } from "../services/people";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import InputWithSuggestions from "../components/InputWithSuggestions";
import RequirementModal from "./RequirementModal";
import { useDiagnosisList, useSubsidiaries } from "../hooks/queries";

/**
 * @typedef API_Person Structure of the Person object returned by the API
 * @property {number} id
 * @property {string} ci
 * @property {string} name
 * @property {"M" | "F"} sex
 * @property {string} birthdate
 * @property {string} phone
 * @property {string} subsidiary
 * @property {boolean} is_holder
 * @property {string | null} holder
 */

/**
 * @typedef TableRequirement Structure of each requirement shown on the table
 * @property {number} requirementType
 * @property {string} requirementTypeLabel
 * @property {number} requirement
 * @property {string} requirementLabel
 * @property {number} medicalCenter
 * @property {string} medicalCenterLabel
 */


/**
 * Tabla de requerimientos
 * @param {object} props
 * @param {TableRequirement[]} props.requirements
 * @param {React.Dispatch<React.SetStateAction<TableRequirement[] | null>>} props.setRequirementList
 * @param {(boolean) => void} props.setShowRequirementModal
 * @param {React.Dispatch<React.SetStateAction<TableRequirement | null>>} props.setEditValues
 * @param {React.Dispatch<React.SetStateAction<number | null>>} props.setCenterSelected
 */
function RequirementsTable({
  requirements,
  setRequirementList,
  setShowRequirementModal,
  setEditValues,
  setCenterSelected
}) {

  /**
   * Remove requirement from list
   * @param {TableRequirement} requirement 
   */
  function deleteRequirement(requirement) {
    const newList = requirements.filter((req) => req !== requirement);
    setRequirementList(newList);
    if (newList.length === 0) {
      setCenterSelected(null);
    }
  }

  /**
   * Show action buttons
   * @param {TableRequirement} row
   */
  const actionBody = (row) => (
    <div className="flex">
      <EditButton onClick={() => {
        setEditValues(row)
        setShowRequirementModal(true);
      }} />
      <DeleteButton onClick={() => deleteRequirement(row)} />
    </div>
  );

  return (
    <DataTable value={requirements} className="mt-5">
      <Column field="requirementTypeLabel" header="Tipo de requerimiento" />
      <Column field="requirementLabel" header="Requerimiento" />
      <Column field="medicalCenterLabel" header="Centro médico" />
      <Column header="Acciones" body={actionBody} />
    </DataTable>
  );
}

const INITIAL_PATIENT_FORM = {
  ciType: "V",
  ci: "",
  name: "",
  sex: null,
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  phoneCode: "58412",
  phoneNumber: "",
  diagnosis: null,
  client: null,
  subsidiary: null,
  isHolder: true,
  shouldCreatePatient: false,
}

const INITIAL_HOLDER_FORM = {
  ciType: "V",
  ci: "",
  name: "",
  sex: null,
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  phoneCode: "58412",
  phoneNumber: "",
  shouldCreateHolder: false,
}

const YEAR_OPTIONS = Array.from({ length: 100 }, (_, i) => String(new Date().getFullYear() - i));


/**
 * Person search item template
 * @param {(item: API_Person) => void} onClick 
 */
function createSearchItemTemplate(onClick) {
  /** @param {API_Person} item */
  return (item) => (
    <span className="py-4" onClick={() => onClick(item)}>{item.ci} - {item.name}</span>
  )
}


/**
 * Formulario para crear un nuevo caso médico
 */
const FormComponent = () => {

  // Form state
  const [patientForm, setPatientForm] = useState(INITIAL_PATIENT_FORM);
  const [holderForm, setHolderForm] = useState(INITIAL_HOLDER_FORM);
  const [inputsLocked, setInputsLocked] = useState("");

  // Number of days available for patient birth date
  const patientNumDays =
    patientForm.patientBirthYear && patientForm.patientBirthMonth
    ? new Date(patientForm.patientBirthYear, patientForm.patientBirthMonth, 0).getDate()
    : 31;

  // Number of days available for holder birth date
  const holderNumDays =
    holderForm.patientBirthYear && holderForm.patientBirthMonth
    ? new Date(holderForm.patientBirthYear, holderForm.patientBirthMonth, 0).getDate()
    : 31;

  // Requirements
  const [showRequirementModal, setShowRequirementModal] = useState(false);
  const [requirementList, setRequirementList] = useState([]);
  const [editValues, setEditValues] = useState(null);
  const [selectedMedicalCenter, setSelectedMedicalCenter] = useState(null);

  // Get diagnosis list
  const diagnosisQuery = useDiagnosisList();

  // Get subsidiaries
  const subsidiariesQuery = useSubsidiaries();

  // Get holder by CI
  const holderQuery = useQuery({
    queryKey: ["holder", holderForm.ciType + holderForm.ci],
    queryFn: () => getHoldersMatchingCI(holderForm.ciType + holderForm.ci),
    enabled: holderForm.ci.length >= 4,
  });

  // Get patient by CI
  const patientQuery = useQuery({
    queryKey: ["patient", patientForm.ciType + patientForm.ci],
    queryFn: () => getPeopleMatchingCI(patientForm.ciType + patientForm.ci),
    enabled: patientForm.ci.length >= 4,
  });

  const queryClient = useQueryClient();

  // Mutation for new case
  const newCaseMutation = useMutation({
    mutationFn: () => addCase({
      patient: patientForm,
      holder: holderForm,
      requirements: requirementList,
    }),
    onSuccess: ({ type, message }) => {
      if (type === "error") {
        toast.current.show({ severity: "error", summary: "Error", detail: message });
      } else {
        queryClient.invalidateQueries({
          queryKey: ["medicalCases"],
        });
        toast.current.show({ severity: "success", summary: "Éxito", detail: "Caso médico registrado" });
        setPatientForm(INITIAL_PATIENT_FORM);
        setHolderForm(INITIAL_HOLDER_FORM);
        setRequirementList([]);
        setInputsLocked("");
        setSelectedMedicalCenter(null);
        holderSearchRef.current.hide();
        patientSearchRef.current.hide();
      }
    }
  });

  // Validate fields being set so requirement can be added
  const canAddRequirement = (patientForm.isHolder && holderForm.ci) || Object
    .keys(patientForm)
    .every((key) => {
      return patientForm[key] !== null && patientForm !== "";
    });

  // Validate fields being set so case can be saved
  const canSaveCase = canAddRequirement && requirementList.length > 0;

  // Form message toast
  const toast = useRef(null);

  // Patient and holder name inputs
  const holderNameRef = useRef(null);
  const patientNameRef = useRef(null);

  // Search overlays
  const holderSearchRef = useRef(null);
  const patientSearchRef = useRef(null);

  /**
   * Key up event for holder search
   * @param {KeyboardEvent} e 
   */
  const onHolderSearchEnter = (e) => {
    if (e.key === "Escape") {
      holderSearchRef.current.hide();
    }
    if (e.key === "Enter") {
      if (!holderQuery.data || !holderQuery.data.length) {
        setHolderForm({ ...holderForm, shouldCreateHolder: true });
        holderSearchRef.current.hide();

        // Wait 50ms for holderNameRef to be set
        setTimeout(() => {
          holderNameRef.current.focus();
        }, 50);
      } else {
        selectHolder(holderQuery.data[0]);
      }
    }
  }

  /**
   * Key up event for patient search
   * @param {KeyboardEvent} e 
   */
  const onPatientSearchEnter = (e) => {
    if (e.key === "Escape") {
      patientSearchRef.current.hide();
    }
    if (e.key === "Enter") {
      if (!patientQuery.data || !patientQuery.data.length) {
        setPatientForm({ ...patientForm, shouldCreatePatient: true });
        patientSearchRef.current.hide();
        patientNameRef.current.focus();
      } else {
        selectPatient(patientQuery.data[0]);
      }
    }
  }

  /**
   * Sets form state from a selected patient
   * @param {API_Person} holder
   */
  const selectHolder = (holder) => {
    setHolderForm({
      ...holderForm,
      ciType: holder.ci.slice(0, 1),
      ci: holder.ci.slice(1),
      shouldCreateHolder: false,
    });
    setPatientForm({ ...patientForm, isHolder: true });
    holderSearchRef.current.hide();
  }

  /**
   * Sets form state from a selected patient
   * @param {API_Person} patient
   */
  const selectPatient = (patient) => {
    setPatientForm({
      ...patientForm,
      ciType: patient.ci.slice(0, 1),
      ci: patient.ci.slice(1),
      name: patient.name,
      sex: patient.sex,
      birthDay: String(Number(patient.birthdate.split("-")[2])),
      birthMonth: Number(patient.birthdate.split("-")[1]),
      birthYear: patient.birthdate.split("-")[0],
      phoneCode: patient.phone.slice(0, 5),
      phoneNumber: patient.phone.slice(5),
      subsidiary: patient.subsidiary,
      diagnosis: null,
      isHolder: false,
      shouldCreatePatient: false,
    })
    setInputsLocked("some");
    patientSearchRef.current.hide();
  }

  return (
    <div className="p-fluid">
      <Toast ref={toast} position="top-center" />

      <form>

        {/* DATOS DEL TITULAR */}
        <FormSubHeading title="Datos del Titular" />
        <div className="p-field">
          <label htmlFor="holderCi">Cédula</label>
          <div className="field-with-select">
            <Dropdown
              id="holderCiType"
              name="holderCiType"
              options={["V", "E"]}
              placeholder="Tipo"
              value={holderForm.ciType}
              onChange={(e) => setHolderForm({ ...holderForm, ciType: e.value })}
              required
              disabled={inputsLocked !== ""}
            />
            <InputWithSuggestions
              inputName="holderCi"
              inputValue={holderForm.ci}
              inputDisabled={inputsLocked === "all"}
              keyfilter="pint"
              maxLength={9}
              onTextChange={(e) => setHolderForm({ ...holderForm, ci: e.target.value })}
              query={holderQuery}
              notFoundBtnLabel="Crear registro de asegurado"
              notFoundBtnClick={() => {
                setHolderForm({ ...holderForm, shouldCreateHolder: true });
                holderSearchRef.current.hide();
                holderNameRef.current.focus();
              }}
              itemTemplate={createSearchItemTemplate(selectHolder)}
              overlayRef={holderSearchRef}
              onSearchEnter={onHolderSearchEnter}
            />
          </div>

          {holderForm.shouldCreateHolder && 
            <>
              <div className="p-field mt-3">
                <label htmlFor="holderName">Nombre completo</label>
                <InputText
                  id="holderName"
                  name="holderName"
                  value={holderForm.name}
                  onChange={(e) => setHolderForm({ ...holderForm, name: e.target.value })}
                  disabled={inputsLocked !== "" || holderQuery.isLoading}
                  ref={holderNameRef}
                />
              </div>
              <div className="p-field">
                <label htmlFor="holderSex">Sexo</label>
                <Dropdown
                  inputId="holderSex"
                  name="holderSex"
                  value={holderForm.sex}
                  options={GENDERS}
                  onChange={(e) => setHolderForm({ ...holderForm, sex: e.value })}
                  placeholder="Seleccionar sexo"
                  disabled={inputsLocked !== "" || holderQuery.isLoading}
                />
              </div>
              <div className="p-field">
                <span id="birthdate-label">Fecha de nacimiento</span>
                <div className="birthdate-container">
                  <Dropdown
                    ariaLabelledBy="birthdate-label"
                    inputId="holderDay"
                    value={holderForm.birthDay}
                    options={Array.from({ length: holderNumDays }, (_, i) => String(i + 1))}
                    onChange={(e) => setHolderForm({ ...holderForm, birthDay: e.value })}
                    placeholder="Día"
                    disabled={inputsLocked !== "" || holderQuery.isLoading}
                    filter
                  />
                  <Dropdown
                    ariaLabelledBy="birthdate-label"
                    inputId="holderMonth"
                    value={holderForm.birthMonth}
                    options={MONTHS}
                    onChange={(e) => setHolderForm({ ...holderForm, birthMonth: e.value })}
                    placeholder="Mes"
                    disabled={inputsLocked !== "" || holderQuery.isLoading}
                    filter
                  />
                  <Dropdown
                    ariaLabelledBy="birthdate-label"
                    inputId="holderYear"
                    value={holderForm.birthYear}
                    options={YEAR_OPTIONS}
                    onChange={(e) => setHolderForm({ ...holderForm, birthYear: e.value })}
                    placeholder="Año"
                    disabled={inputsLocked !== "" || holderQuery.isLoading}
                    filter
                  />
                </div>
              </div>
              <div className="p-field">
                <label htmlFor="holderPhoneNumber">Número de teléfono</label>
                <div className="field-with-select">
                  <Dropdown
                    inputId="holderPhoneCode"
                    name="holderPhoneCode"
                    options={PHONE_CODES}
                    placeholder="Código"
                    value={holderForm.phoneCode}
                    onChange={(e) => setHolderForm({ ...holderForm, phoneCode: e.value })}
                    disabled={inputsLocked !== "" || holderQuery.isLoading}
                  />
                  <InputText
                    id="holderPhoneNumber"
                    name="holderPhoneNumber"
                    inputMode="numeric"
                    keyfilter="pint"
                    maxLength={7}
                    value={holderForm.phoneNumber}
                    onChange={(e) => setHolderForm({ ...holderForm, phoneNumber: e.target.value })}
                    disabled={inputsLocked !== "" || holderQuery.isLoading}
                  />
                </div>
              </div>
            </>
          }

          <div className="flex flex-row align-items-center gap-3 pt-2">
            <span>¿El titular es el paciente?</span>
            <SelectButton
              value={patientForm.isHolder}
              onChange={(e) => setPatientForm({ ...patientForm, isHolder: e.value, shouldCreatePatient: e.value, })}
              options={[
                { label: "Si", value: true },
                { label: "No", value: false }
              ]}
              disabled={inputsLocked !== "" || patientQuery.isLoading || holderQuery.isLoading}
              allowEmpty={false}
            />
          </div>
        </div>

        {!patientForm.isHolder && (
          <>
            {/* DATOS DEL PACIENTE */}
            <FormSubHeading title="Datos del Paciente" />

            <div className="p-field">
              <label htmlFor="ciPatient">Cédula</label>
              <div className="field-with-select">
                <Dropdown
                  inputId="patientCiType"
                  name="patientCiType"
                  options={["V", "E"]}
                  placeholder="Tipo"
                  value={patientForm.ciType}
                  onChange={(e) => setPatientForm({ ...patientForm, ciType: e.value })}
                  required
                  disabled={inputsLocked === "all"}
                />
                <InputWithSuggestions
                  inputName="patientCi"
                  inputValue={patientForm.ci}
                  inputDisabled={inputsLocked === "all"}
                  keyfilter="pint"
                  maxLength={9}
                  onTextChange={(e) => setPatientForm({ ...patientForm, ci: e.target.value })}
                  query={patientQuery}
                  notFoundBtnLabel="Crear paciente con esta cédula"
                  notFoundBtnClick={() => {
                    setPatientForm({ ...patientForm, shouldCreatePatient: true });
                    patientSearchRef.current.hide();
                    patientNameRef.current.focus();
                  }}
                  itemTemplate={createSearchItemTemplate(selectPatient)}
                  overlayRef={patientSearchRef}
                  onSearchEnter={onPatientSearchEnter}
                />
              </div>
            </div>

            <div className="p-field">
              <label htmlFor="patientName">Nombre completo</label>
              <InputText
                id="patientName"
                name="patientName"
                value={patientForm.name}
                onChange={(e) => setPatientForm({ ...patientForm, name: e.target.value })}
                disabled={inputsLocked !== "" || patientQuery.isLoading}
                ref={patientNameRef}
              />
            </div>

            <div className="p-field">
              <label htmlFor="gender">Sexo</label>
              <Dropdown
                inputId="gender"
                name="gender"
                value={patientForm.sex}
                options={GENDERS}
                onChange={(e) => setPatientForm({ ...patientForm, sex: e.value })}
                placeholder="Seleccionar sexo"
                disabled={inputsLocked !== "" || patientQuery.isLoading}
              />
            </div>

            <div className="p-field">
              <span id="birthdate-label">Fecha de nacimiento</span>
              <div className="birthdate-container">
                <Dropdown
                  ariaLabelledBy="birthdate-label"
                  inputId="day"
                  value={patientForm.birthDay}
                  options={Array.from({ length: patientNumDays }, (_, i) => String(i + 1))}
                  onChange={(e) => setPatientForm({ ...patientForm, birthDay: e.value })}
                  placeholder="Día"
                  disabled={inputsLocked !== "" || patientQuery.isLoading}
                  filter
                />
                <Dropdown
                  ariaLabelledBy="birthdate-label"
                  inputId="month"
                  value={patientForm.birthMonth}
                  options={MONTHS}
                  onChange={(e) => setPatientForm({ ...patientForm, birthMonth: e.value })}
                  placeholder="Mes"
                  disabled={inputsLocked !== "" || patientQuery.isLoading}
                  filter
                />
                <Dropdown
                  ariaLabelledBy="birthdate-label"
                  inputId="year"
                  value={patientForm.birthYear}
                  options={YEAR_OPTIONS}
                  onChange={(e) => setPatientForm({ ...patientForm, birthYear: e.value })}
                  placeholder="Año"
                  disabled={inputsLocked !== "" || patientQuery.isLoading}
                  filter
                />
              </div>
            </div>

            <div className="p-field">
              <label htmlFor="phoneNumber">Número de teléfono</label>
              <div className="field-with-select">
                <Dropdown
                  inputId="phoneCode"
                  name="phoneCode"
                  options={PHONE_CODES}
                  placeholder="Código"
                  value={patientForm.phoneCode}
                  onChange={(e) => setPatientForm({ ...patientForm, phoneCode: e.value })}
                  disabled={inputsLocked !== "" || patientQuery.isLoading}
                />
                <InputText
                  id="phoneNumber"
                  name="phoneNumber"
                  inputMode="numeric"
                  keyfilter="pint"
                  maxLength={7}
                  value={patientForm.phoneNumber}
                  onChange={(e) => setPatientForm({ ...patientForm, phoneNumber: e.target.value })}
                  disabled={inputsLocked !== "" || patientQuery.isLoading}
                />
              </div>
            </div>
          </>
        )}

        {/* SELECCIÓN DE DIAGNÓSTICO */}
        <FormSubHeading title="Diagnóstico" />
        <div className="p-field">
          <label htmlFor="diagnosis">Diagnóstico</label>
          <MultiSelect
            inputId="diagnosis"
            name="diagnosis"
            value={patientForm.diagnosis}
            onChange={(e) => setPatientForm({ ...patientForm, diagnosis: e.value })}
            options={diagnosisQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccionar diagnóstico"
            filter
            loading={diagnosisQuery.isLoading}
            disabled={diagnosisQuery.isLoading || inputsLocked === "all"}
            virtualScrollerOptions={{ itemSize: 48, style: { width: "650px" } }}
          />
        </div>

        {/* SELECCIÓN DE FILIAL */}
        <FormSubHeading title="Filial" />
        <div className="p-field">
          <label htmlFor="subsidiary">Filial</label>
          <Dropdown
            inputId="subsidiary"
            name="subsidiary"
            options={subsidiariesQuery.data ?? []}
            value={patientForm.subsidiary}
            onChange={(e) => setPatientForm(
              {
                ...patientForm,
                subsidiary: e.value,
                client: subsidiariesQuery.data.find((subsidiary) => subsidiary.id === e.value)?.company_client ?? null
              }
            )}
            optionLabel="name"
            optionValue="id"
            placeholder="Seleccionar filial"
            filter
            disabled={inputsLocked !== "" || patientQuery.isLoading || subsidiariesQuery.isLoading}
            loading={subsidiariesQuery.isLoading}
          />
        </div>
      </form>

      {!!requirementList.length && (
        <>
          {/* TABLA DE REQUERIMIENTOS */}
          <FormSubHeading title="Requerimientos" />
          <RequirementsTable
            requirements={requirementList}
            setRequirementList={setRequirementList}
            setShowRequirementModal={setShowRequirementModal}
            setEditValues={setEditValues}
            setCenterSelected={setSelectedMedicalCenter}
          />
        </>
      )}

      <Button
        type="button"
        icon="pi pi-plus"
        label="Añadir requerimiento"
        className="mt-4"
        severity="info"
        disabled={!canAddRequirement}
        onClick={() => {
          setEditValues(null);
          setShowRequirementModal(true);
        }}
      />

      {!!canSaveCase && (
        <Button
          type="button"
          icon="pi pi-save"
          label="Guardar caso"
          className="mt-4"
          severity="success"
          loading={newCaseMutation.isLoading}
          disabled={newCaseMutation.isLoading}
          onClick={() => newCaseMutation.mutate()}
        />
      )}

      {/* MODAL DE REQUERIMIENTOS */}
      <RequirementModal
        showModal={showRequirementModal}
        onHide={() => {
          if (!showRequirementModal) return;
          setEditValues(null);
          setShowRequirementModal(false);
        }}
        setRequirementList={setRequirementList}
        setInputsLocked={setInputsLocked}
        initialValues={editValues}
        patientClient={patientForm.client}
        centerSelected={selectedMedicalCenter}
        setCenterSelected={setSelectedMedicalCenter}
      />
    </div>
  );
};

export default FormComponent;
