import { Column } from "primereact/column";
import Dashboard from "../Layout/Dashboard";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { useScales } from "../hooks/queries";
import { Link, useNavigate } from "react-router-dom";

/**
 * Displays the list of scales by client and name
 */
export default function BaremosListing() {

  // Table filters
  const [filters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    company_client: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // Get baremo data
  const scalesQuery = useScales();

  const navigate = useNavigate();
  function navigateToScale(event) {
    navigate(String(event.data.id));
  }

  /**
   * Action buttons for scale
   */
  function scalesActionTemplate(row) {
    return (
      <div className="d-flex justify-content-center">
        <Link to={String(row.id)} className="p-button mb-3">
          <span className="p-button-icon p-button-icon-left pi pi-eye"></span>
          <span className="p-button-label">Ver</span>
        </Link>
      </div>
    );
  }
  
  return (
    <Dashboard headerTitle="Baremos">
      <Link to="nuevo" className="p-button mb-3">
        <span className="p-button-icon p-button-icon-left pi pi-plus"></span>
        <span className="p-button-label">Crear Baremo</span>
      </Link>
      <DataTable
        value={scalesQuery.data ?? []}
        style={{ minWidth: "50rem" }}
        stripedRows
        selectionMode="single"
        filterDisplay="row"
        removableSort
        loading={scalesQuery.isLoading}
        emptyMessage="No se encontraron baremos."
        filters={filters}
        onRowSelect={(event) => navigateToScale(event)}
      >
        <Column
          field="name"
          header="Nombre"
          sortable
          filter
          showFilterMenu={false}
          filterPlaceholder="Buscar por nombre"
        />
        <Column
          field="company_client"
          header="Cliente"
          sortable
          filter
          showFilterMenu={false}
          filterPlaceholder="Buscar por cliente"
          body={(row) => row.company_client.join(", ")}
        />
        <Column
          header="Acciones"
          body={scalesActionTemplate}
        />
      </DataTable>
    </Dashboard>
  );
}
