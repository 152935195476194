import { BASE_URL } from "../constants";
import { getAuthHeader } from "../utils";

/**
 * @typedef Bank
 * @property {number} id
 * @property {string} name
 */

/**
 * Get registered banks
 */
export async function getBanks() {
  try {
    const response = await fetch(BASE_URL + "currency/bank/", {
      headers: {
        ...getAuthHeader(),
      },
    });
    const data = await response.json();

    /**@type Bank[] */
    const banks = data.results;
    return banks;
  } catch (error) {
    console.error("Error fetching banks", error);
    return [];
  }
}
