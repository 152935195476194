import React from 'react';
import { authLogout } from "../services/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        authLogout().then(()=>{
            navigate("/login");
        });
    };
    // handler enter page
    useEffect(() => {
        handleLogout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <button onClick={handleLogout}>
            Logout
        </button>
    );
};

export default Logout;