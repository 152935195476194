// Services for people (holders and patients)

import { BASE_URL } from "../constants";
import { getAuthHeader } from "../utils";

/**
 * @typedef Person
 * @property {number} id
 * @property {string} ci
 * @property {string} name
 * @property {"M" | "F"} sex
 * @property {string} birthdate
 * @property {string} phone
 * @property {string} subsidiary
 * @property {boolean} is_holder
 * @property {number} insurance
 * @property {number | null} holder
 */


/**
 * Create a person
 */
export async function addPerson(data) {
  try {
    // Add person
    const response = await fetch(BASE_URL + "insurance/person/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const person = await response.json();
    return { type: "success", message: "Persona agregada correctamente.", personId: person.id };
  } catch (error) {
    console.error("Error adding person: ", error);
    return { type: "error", message: error.message };
  }
}

/**
 * Gets list of people matching CI from left to right
 * @param {string | undefined} ci 
 */
export async function getPeopleMatchingCI(ci) {
  try {
    let query = ci;
    if (!query) {
      query = "";
    }
    const response = await fetch(BASE_URL + "insurance/person/?ci__istartswith=" + query, {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    /**@type Person[] */
    const results = data.results;
    return results ?? [];
  } catch (error) {
    console.error("Error fetching person: ", error);
    return [];
  }
}

/**
 * Gets list of holders matching CI from left to right
 * @param {string | undefined} ci 
 */
export async function getHoldersMatchingCI(ci) {
  const people = await getPeopleMatchingCI(ci);
  return people.filter((person) => person.is_holder);
}
