// Constantes

/**
 * API base URL
 */
export const BASE_URL = "https://api.citamed247.com/api/v1/";
// export const BASE_URL = "http://localhost:8002/api/v1/";

/**
 * Genders list for dropdowns
 */
export const GENDERS = [
  { label: "Masculino", value: "M" },
  { label: "Femenino", value: "F" },
];

/**
 * Phone codes for dropdowns
 */
export const PHONE_CODES = [
  { label: "0412", value: "58412" },
  { label: "0414", value: "58414" },
  { label: "0416", value: "58416" },
  { label: "0424", value: "58424" },
  { label: "0426", value: "58426" },
];

/**
 * Months list for dropdowns
 */
export const MONTHS = [
  { label: "Enero", value: 1 },
  { label: "Febrero", value: 2 },
  { label: "Marzo", value: 3 },
  { label: "Abril", value: 4 },
  { label: "Mayo", value: 5 },
  { label: "Junio", value: 6 },
  { label: "Julio", value: 7 },
  { label: "Agosto", value: 8 },
  { label: "Septiembre", value: 9 },
  { label: "Octubre", value: 10 },
  { label: "Noviembre", value: 11 },
  { label: "Diciembre", value: 12 },
];

/**
 * List of user roles for dropdowns
 */
export const USER_ROLES_DROPDOWN = [
  { label: "Conciliador", value: "conciliator" },
  { label: "Auditor", value: "auditor" },
  { label: "Gerente general", value: "manager" },
  { label: "Analista regional", value: "analyst" },
  { label: "Proveedor", value: "medicalCenter" },
];

/**
 * List of day numbers with labels
 */
export const DAYS = [
  { id: 1, shortLabel: "Lun.", fullLabel: "Lunes", apiLabel: "lunes" },
  { id: 2, shortLabel: "Mar.", fullLabel: "Martes", apiLabel: "martes" },
  { id: 3, shortLabel: "Mie.", fullLabel: "Miércoles", apiLabel: "miércoles" },
  { id: 4, shortLabel: "Jue.", fullLabel: "Jueves", apiLabel: "jueves" },
  { id: 5, shortLabel: "Vie.", fullLabel: "Viernes", apiLabel: "viernes" },
  { id: 6, shortLabel: "Sab.", fullLabel: "Sábado", apiLabel: "sábado" },
  { id: 7, shortLabel: "Dom.", fullLabel: "Domingo", apiLabel: "domingo" },
];

/**
 * lIst of possible medical case file types
 */
export const MEDICAL_FILE_TYPES = [
  { label: "Orden médica", value: "orden_medica" },
  { label: "Examen médico", value: "examen_medico" },
  { label: "Resultado de examen", value: "resultado_examen" },
  { label: "Receta médica", value: "receta_medica" },
  { label: "Estudio médico", value: "estudio_medico" },
  { label: "Cédula", value: "cedula" },
  { label: "Ficha del titular", value: "ficha_titular" },
  { label: "Informe médico", value: "informe_medico" },
  { label: "Imagenología", value: "imagenologia" },
  { label: "Otro", value: "otro" },
];

/**
 * Class of available user roles
 */
export class UserRole {
  static MASTER = "master";
  static CONCILIATOR = "conciliator";
  static AUDITOR = "auditor";
  static MEDICAL_CENTER = "medical_center";
  static ANALYST = "analyst";
  static MANAGER = "manager";
  static ANALYST_SUPPLIER = "analyst_supplier";
  static all() {
    return [
      UserRole.MASTER,
      UserRole.CONCILIATOR,
      UserRole.AUDITOR,
      UserRole.MEDICAL_CENTER,
      UserRole.ANALYST,
      UserRole.MANAGER,
      UserRole.ANALYST_SUPPLIER,
    ];
  }
}

const ROLE_TO_ROUTE = {
  [UserRole.MASTER]: "/casos",
  [UserRole.CONCILIATOR]: "/casos",
  [UserRole.AUDITOR]: "/auditorias",
  [UserRole.MEDICAL_CENTER]: "/casos",
  [UserRole.ANALYST]: "/casos",
  [UserRole.MANAGER]: "/casos",
  [UserRole.ANALYST_SUPPLIER]: "/proveedores",
};

export const getRouteForRole = (role) => {
  return ROLE_TO_ROUTE[role] || "/"; // Ruta por defecto si el rol no está definido
};

/**
 * List of roles with allowed access per route
 */
export const ROUTE_ALLOWED_ROLES = {
  // Casos Médicos
  "/casos/nuevo": [UserRole.CONCILIATOR, UserRole.ANALYST, UserRole.MANAGER],
  "/casos": [
    UserRole.CONCILIATOR,
    UserRole.MEDICAL_CENTER,
    UserRole.ANALYST,
    UserRole.MANAGER,
  ],
  "/casos/:caseId": [
    UserRole.CONCILIATOR,
    UserRole.MEDICAL_CENTER,
    UserRole.ANALYST,
    UserRole.MANAGER,
  ],
  "/requerimientos": [UserRole.CONCILIATOR, UserRole.MEDICAL_CENTER],

  // Anticipos
  "/anticipos/nuevo": [UserRole.CONCILIATOR, UserRole.AUDITOR],
  "/anticipos": [
    UserRole.CONCILIATOR,
    UserRole.AUDITOR,
    UserRole.MEDICAL_CENTER,
  ],
  "/anticipos/proveedores/:centerId": [
    UserRole.MEDICAL_CENTER,
    UserRole.CONCILIATOR,
    UserRole.AUDITOR,
  ],

  // Baremos
  "/baremos": [
    UserRole.CONCILIATOR,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
  ],
  "/baremos/nuevo": [
    UserRole.CONCILIATOR,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
  ],
  "/baremos/:baremoId": [
    UserRole.CONCILIATOR,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
  ],

  // Proveedores
  "/proveedores": [
    UserRole.CONCILIATOR,
    UserRole.MEDICAL_CENTER,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
  ],
  "/proveedores/nuevo": [
    UserRole.CONCILIATOR,
    UserRole.MEDICAL_CENTER,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
  ],
  "/centro-medico/servicios": [
    UserRole.CONCILIATOR,
    UserRole.MEDICAL_CENTER,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,
  ],
  "/proveedores/editar/:centerId": [
    UserRole.CONCILIATOR,
    UserRole.MEDICAL_CENTER,
    UserRole.MANAGER,
    UserRole.AUDITOR,
    UserRole.ANALYST_SUPPLIER,

  ],

  // Facturas
  "/centro-medico/facturas": [UserRole.MEDICAL_CENTER],
  "/centro-medico/facturas/nueva": [UserRole.MEDICAL_CENTER],

  // Usuarios
  "/usuarios": [UserRole.CONCILIATOR],
  // Logout
  "/logout": UserRole.all(),
};
