import { BASE_URL } from "../constants";

/**
 * @typedef DollarRateResponse
 * @property {number} id
 * @property {string} date
 * @property {string} rate
 * @property {string} created_at
 */


/**
 * Gets current BCV dolar rate from API
 */
export async function getCurrentDollarRate() {
  try {
    const response = await fetch(`${BASE_URL}currency/dollar-rate/current_rate/`);
    if (response.ok) {
      /**@type {DollarRateResponse} */
      const data = await response.json();
      console.log(data);
      return data;
    }
    throw new Error("Error getting dollar rate");
  } catch (error) {
    console.error("Error getting dollar rate: ", error);
    return 0;
  }
}
