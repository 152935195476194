import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { DeleteButton, EditButton } from "../components/TableButtons";

/**
 * DataTable component for displaying medical center requirements
 * @param {object} props
 * @param {object[]} props.value
 * @param {Function} props.onClickEdit
 * @param {Function} props.onClickDelete
 * @param {boolean} props.loading
 */
export default function CenterRequirementsTable({ value, onClickEdit, onClickDelete, loading = false }) {

	// Table filters
  const [filters] = useState({
    company_client_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    requirement_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type_requirement_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  /**
   * Returns days attended
   */
  const daysAttendedTemplate = (row) => (
    row.days.map((day) => day.fullLabel).join(", ")
  );

	/**
   * Delete button for rows
   * @param {typeof slots[number]} row 
   */
  const actionBodyTemplate = (row) => (
    <div className="flex justify-content-center">
      <EditButton onClick={onClickEdit(row)} />
      <DeleteButton onClick={onClickDelete(row)} />
    </div>
  );

	return (
		<DataTable
			stripedRows
			value={value}
			emptyMessage="No ha registrado servicios para este proveedor"
			filterDisplay="row"
			filters={filters}
			removableSort
      		loading={loading}
			scrollable scrollHeight="400px" 
			virtualScrollerOptions={{ itemSize: 40 }}
		>
			<Column
				header="Cliente"
				field="company_client_name"
				sortable
				filter
				showFilterMenu={false}
				filterPlaceholder="Buscar cliente"
			/>
			<Column
				header="Servicio"
				field="requirement_name"
				sortable
				filter
				showFilterMenu={false}
				filterPlaceholder="Buscar servicio"
			/>
			<Column
				header="Tipo de servicio"
				field="type_requirement_name"
				sortable
				filter
				showFilterMenu={false}
				filterPlaceholder="Buscar tipo de servicio"
			/>
			<Column header="Días atendido" field="days" body={daysAttendedTemplate} />
			<Column
				header="REF"
				field="price_ref"
				sortable
				showFilterMenu={false}
				filterPlaceholder="Buscar tipo de servicio"
			/>
			<Column header="Acción" body={actionBodyTemplate} />
		</DataTable>
	);
}
