import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import VSieteCurrencyInput from "../components/VSieteCurrencyInput";
import { Button } from "primereact/button";

/**
 * Modal to add requirement to scale, or edit an existing one
 * @param {object} props
 * @param {boolean} props.show
 * @param {() => void} props.onHide
 * @param {(data: object) => void} props.saveData
 * @param {import("@tanstack/react-query").UseQueryResult} props.requirementsQuery
 * @param {object} props.formState
 * @param {React.Dispatch<React.SetStateAction<object>>} props.setFormState
 * @param {Function} props.requirementsFilter
 */
export default function BaremoRequirementModal({
  show,
  onHide,
  saveData,
  requirementsQuery,
  formState,
  setFormState,
  requirementsFilter,
  disableDropdown,
}) {
   
  const canSave = formState.requirement && formState.price;

  let requirementOptions = requirementsQuery.data ?? [];
  if (!formState.requirement) {
    requirementOptions = requirementOptions.filter(requirementsFilter);
  }

  return (
    <Dialog visible={show} onHide={onHide} draggable={false} header="Asociar requerimiento" style={{ width: "40vw" }}>
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="requirement">Requerimiento</label>
          <Dropdown
            value={formState.requirement}
            onChange={(e) => setFormState({ ...formState, requirement: e.value })}
            loading={requirementsQuery.isLoading}
            disabled={requirementsQuery.isLoading || disableDropdown}
            options={requirementOptions}
            dataKey="id"
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccionar requerimiento"
            filter
            virtualScrollerOptions={{ itemSize: 42 }}
          />
        </div>
        <div className="p-field">
          <label htmlFor="price">Precio</label>
          <VSieteCurrencyInput
            id="price"
            name="price"
            prefix="$"
            value={formState.price}
            onValueChange={(_value, _name, values) => setFormState({ ...formState, price: values.float })}
          />
        </div>
        <Button
          type="button"
          onClick={() => {
            saveData({
              ...formState,
              name: requirementOptions.find((req) => req.id === formState.requirement)?.name,
              typeName: requirementOptions.find((req) => req.id === formState.requirement)?.type_requirement_name,
            });
            setFormState({
              requirement: null,
              price: "",
              action: "create",
            });
          }}
          label="Guardar"
          disabled={!canSave}
        />
      </div>
    </Dialog>
  );
}
