import Dashboard from "../Layout/Dashboard";
import { useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import FormSubHeading from "../components/FormSubHeading";
import {
  currencyFormat,
  formatCi,
  formatDateString,
  formatFullNumber,
  readableSex,
} from "../utils";
import { FileUpload } from "primereact/fileupload";
import { useRequirementTypes, useSingleMedicalCase } from "../hooks/queries";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateMedicalCase } from "../services/medicalCases";
import { BASE_URL, MEDICAL_FILE_TYPES } from "../constants";
import { Toast } from "primereact/toast";

/**
 * Detalle del caso médico
 */
export default function CaseDetail() {
  // Buscar detalle del caso
  const { caseId } = useParams();
  const { data, isLoading } = useSingleMedicalCase(caseId);

  return (
    <Dashboard headerTitle="Detalle del caso" backButtonUrl="/casos">
      {isLoading && <ProgressSpinner />}
      {data && !isLoading && <CaseDetailContent data={data} />}
      {!data && !isLoading && <p>No se encontró el caso médico</p>}
    </Dashboard>
  );
}

/**
 * Show detail when case is loaded
 * @param {object} props
 * @param {MedicalCase} props.data
 */
function CaseDetailContent({ data }) {
  // Initialize state for requirement state
  const [requirementsDone, setRequirementsDone] = useState(
    data.requirements.map((req) => ({
      requirement: req.requirement,
      done: false,
    }))
  );

  // State for dialog
  const [showFileDialog, setShowFileDialog] = useState(false);

  // State for medical files
  const [medicalFiles, setMedicalFiles] = useState(
    data.files.map((file)=>({
      file: file.file,
      fileName: file.file_name,
      fileType: file.type_requirement,
    })
  ));

  // State for invoice
  const [invoiceFile, setInvoiceFile] = useState(null);

  // read changes in data
  const [invoice, setInvoice] = useState(
    data.invoice.map((invoice) => ({
      file: invoice.file,
      fileName: invoice.file_name,
    }))
  );

  const toastRef = useRef(null);

  // Change requirement done state
  const changeRequirementDone = (requirement, done) => {
    const newRequirementsDone = requirementsDone.map((req) => {
      if (req.requirement === requirement) {
        return {
          ...req,
          done,
        };
      }
      return req;
    });
    setRequirementsDone(newRequirementsDone);
  };

  /**
   * Save medical file from dialog
   * @param {{ fileType: string, file: File & { objectURL: string } }} medicalFile
   */
  const onSaveMedicalFile = (medicalFile) => {
    setMedicalFiles((prev) => {
      return [...prev, medicalFile];
    });
  };

  const queryClient = useQueryClient();

  const usePatchMedicalCase = useMutation({
    mutationFn: updateMedicalCase,
    onSuccess: ({ type, message }) => {
      if (type === "success") {
        toastRef.current.show({
          severity: "success",
          summary: "Éxito",
          detail: message,
        });
        queryClient.invalidateQueries({
          queryKey: ["medicalCase", data.id],
        });
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
      }
    },
  });

  /**
   * Show preview of an invoice file
   * @param {{ file: string, fileName: string } | undefined} row 
   */
  const invoiceFilePreviewBody = (row) => {
    if (
      row?.fileName !== undefined &&
      (row.fileName?.endsWith(".jpeg") || row.fileName?.endsWith(".jpg") || row.fileName?.endsWith(".png"))
    ) {
      return (
        <img
          role="presentation"
          src={row.file}
          alt={row.fileName}
          width={80}
        ></img>
      );
    } else {
      return <></>;
    }
  };

  /**
   * Show actions for a medical file
   * @param {{ file: string, fileName: string }} row 
   */
  const medicalFileActionBody = (row) => (
    <Button
      icon="pi pi-times"
      rounded
      text
      severity="danger"
      aria-label="Eliminar"
      tooltip="Eliminar soporte"
      onClick={() => {
        setMedicalFiles((prev) => {
          return prev.filter((file) => file.file !== row.file);
        });
      }}
    />
  );

  /**
   * Show toast for successful upload
   */
  const onUpload = () => {
    toastRef.current.show({
      severity: "success",
      summary: "Éxito",
      detail: "Archivo subido con éxito",
    });
  }

  return (
    <div className="p-fluid">
      <h3 className="mt-1 text-right">
        <i className="pi pi-ticket"></i> Número de Orden: {data.id}
      </h3>
      <Toast ref={toastRef} position="top-center" />

      <FormSubHeading title="Datos del paciente" />
      <div>
        <p>
          <i className="pi pi-id-card"></i> <strong>Cédula:</strong>{" "}
          {formatCi(data.patient_.ci)}
        </p>
        <p>
          <i className="pi pi-address-book"></i> <strong>Nombre:</strong>{" "}
          {data.patient_.name}
        </p>
        <p>
          <i className="pi pi-phone"></i> <strong>Teléfono:</strong>{" "}
          {formatFullNumber(data.patient_.phone)}
        </p>
        <p>
          <i className="pi pi-calendar"></i>{" "}
          <strong>Fecha de Nacimiento:</strong>{" "}
          {formatDateString(data.patient_.birthdate)}
        </p>
        <p>
          <i className="pi pi-user"></i> <strong>Sexo:</strong>{" "}
          {readableSex(data.patient_.sex)}
        </p>
      </div>

      <FormSubHeading title="Datos del titular" />
      <div>
        <p>
          <i className="pi pi-id-card"></i> <strong>Cédula:</strong>{" "}
          {formatCi(data.holder_.ci)}
        </p>
      </div>

      <FormSubHeading title="Datos del caso y servicios" />
      <div className="mb-4">
        <p>
          <i className="pi pi-briefcase"></i> <strong>Filial:</strong>{" "}
          {data.subsidiary_name}
        </p>
        <p>
          <i className="pi pi-wave-pulse"></i> <strong>Diagnóstico:</strong>{" "}
          {data.diagnosis_name}
        </p>
        <p>
          <i className="pi pi-building-columns"></i> <strong>Proveedor:</strong>{" "}
          {data.medical_center_name}
        </p>
        <p>
          <i className="pi pi-money-bill"></i> <strong>Monto:</strong> Bs.{" "}
          {currencyFormat(data.amount)}
        </p>
        <DetailRequirementsTable
          value={data.requirements}
          requirementsDone={requirementsDone}
          changeRequirementDone={changeRequirementDone}
        />
      </div>

      <FormSubHeading title="Factura" />
      <div className="mb-4">
        <FileUpload
          name="file"
          mode="advanced"
          chooseLabel="Elegir archivo"
          uploadOptions={{
            style: { display: "none" },
          }}
          cancelLabel="Cancelar"
          emptyTemplate={<p className="m-0">Cargar factura del caso médico</p>}
          onSelect={(e) => {
            console.log(setInvoice)
            if (e.files.length) {
              setInvoiceFile(e.files[0]);
            } else {
              setInvoiceFile(null);
            }
          }}
          url={`${BASE_URL}insurance/medical-case-invoice/`}
          auto
          accept=".jpeg,.jpg,.png,.pdf"
          onBeforeUpload={({ formData, xhr }) => {
            formData.append("medical_case", data.id);
            console.log(invoiceFile)
            console.log(xhr.response);
            const reqListener = (r) => {
              console.log("rrr", r);
              console.log(r.target.response);
              // convert the JSON string to a JSON object
              const data = JSON.parse(r.target.response);
              console.log(data);
            };
            xhr.addEventListener("load", reqListener);
          }}
          onUpload={onUpload}
          onBeforeSend={(e) => {
            console.log("onBeforeSend", e);
          }}
          maxFileSize={5000000} // 5MB
          invalidFileSizeMessageDetail="El tamaño máximo permitido es 5MB"
        />
        {!!invoice.length && (
          <DataTable stripedRows value={invoice} className="mt-2">
            <Column header="Vista previa" body={invoiceFilePreviewBody} />
            <Column header="Nombre" field="fileName" />
            <Column header="Acción" body={medicalFileActionBody} />
          </DataTable>
        )}
      </div>

      <FormSubHeading title="Cargar soportes médicos" />
      <Button
        label="Agregar soporte"
        onClick={() => setShowFileDialog(true)}
        icon="pi pi-file-arrow-up"
        className="max-w-max"
      />
      <MedicalFileDialog
        show={showFileDialog}
        onHide={() => {
          if (showFileDialog) setShowFileDialog(false);
        }}
        onSave={onSaveMedicalFile}
        onUpload={onUpload}
        caseId={data.id}
      />

      <div className="mb-4">
        {!!medicalFiles.length && (
          <DataTable stripedRows value={medicalFiles} className="mt-2">
            <Column header="Vista previa" body={invoiceFilePreviewBody} />
            <Column header="Nombre" field="fileName" />
            <Column
              header="Tipo"
              field="fileType"
              body={(row) => {
                const match = MEDICAL_FILE_TYPES.find((type) => type.value === row.fileType)
                return match?.label ?? "Otro";
              }}
            />
            <Column header="Acción" body={medicalFileActionBody} />
          </DataTable>
        )}
      </div>

      <Button
        icon="pi pi-save"
        label="Guardar cambios"
        onClick={() => {
          usePatchMedicalCase.mutate({
            caseId: data.id,
            data: {status: "atendido"}
          });
        }}
        severity="success"
        className="mt-3 ml-2 max-w-max"
        // disabled={
        //   !!medicalFiles.length &&
        //   requirementsDone.some((req) => req.done) &&
        //   invoiceFile
        //     ? false
        //     : true
        // }
      />
      {/* {!!medicalFiles.length && requirementsDone.some((req) => req.done) && invoiceFile && (
        <Button
          icon="pi pi-save"
          label="Guardar cambios"
          severity="success"
          className="mt-3 max-w-max"
          disabled={false}
        />
      )} */}
    </div>
  );
}

/**
 * Displays requirements of case in a table
 */
function DetailRequirementsTable({
  value,
  requirementsDone,
  changeRequirementDone,
}) {
  // Table filters
  const [filters] = useState({
    requirement_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type_requirement: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  // Get requirement types
  const requirementTypesQuery = useRequirementTypes();

  /**
   * Dropdown to filter case requirement type
   */
  const requirementTypeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      loading={requirementTypesQuery.isLoading}
      disabled={requirementTypesQuery.isLoading}
      options={requirementTypesQuery.data ?? []}
      optionValue="name"
      optionLabel="name"
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Seleccionar tipo"
      className="p-column-filter"
      showClear
      filter
      style={{ maxWidth: "10rem" }}
    />
  );

  return (
    <DataTable
      stripedRows
      value={value}
      emptyMessage="No ha registrado requerimientos para este proveedor"
      dataKey="requirement"
      filterDisplay="row"
      filters={filters}
      removableSort
    >
      <Column
        header="Servicio"
        field="requirement_name"
        sortable
        filter
        showFilterMenu={false}
        filterPlaceholder="Buscar por servicio"
      />
      <Column
        header="Tipo de servicio"
        field="type_requirement"
        sortable
        filter
        filterElement={requirementTypeFilterTemplate}
        showFilterMenu={false}
        filterPlaceholder="Buscar tipo de servicio"
      />
      <Column header="Monto (Bs.)" field="amount" sortable />
      <Column
        header="¿Realizado?"
        headerTooltip="Marque si ya se ha completado este requerimiento"
        bodyClassName="text-center"
        body={(row) => (
          <Checkbox
            inputId={`Requerimiento ${row.requirement_name} completado`}
            checked={
              requirementsDone.find(
                (req) => req.requirement === row.requirement
              )?.done
            }
            onChange={(e) => changeRequirementDone(row.requirement, e.checked)}
          />
        )}
      />
    </DataTable>
  );
}

/**
 * Dialog to upload a medical file
 * @param {object} props
 * @param {boolean} props.show
 * @param {() => void} props.onHide
 * @param {(state: { fileType: string, file: File & { objectURL: string } }) => void} props.onSave
 * @param {() => void} props.onUpload
 * @param {number} props.caseId
 */
function MedicalFileDialog({ show, onHide, onSave, onUpload, caseId }) {
  const [formState, setFormState] = useState({
    fileType: null,
    file: null,
    fileName: "",
  });

  return (
    <Dialog
      header="Cargar soporte médico"
      visible={show}
      onHide={onHide}
      draggable={false}
      style={{ minWidth: "35rem" }}
      contentClassName="p-fluid"
    >
      <div className="p-field">
        <label htmlFor="medicalFileType">Tipo de soporte</label>
        <Dropdown
          inputId="medicalFileType"
          className="mt-2"
          options={MEDICAL_FILE_TYPES}
          placeholder="Seleccionar tipo"
          value={formState.fileType}
          onChange={(e) => setFormState({ ...formState, fileType: e.value })}
        />
      </div>
      <div className="p-field p-fluid">
        <label htmlFor="medicalFile">Soporte médico</label>
        <FileUpload
          className="mt-2"
          name="file"
          mode="advanced"
          chooseLabel="Elegir archivo"
          uploadOptions={{
            style: { display: "none" },
          }}
          cancelLabel="Cancelar"
          emptyTemplate={<p className="m-0">Cargar Informe o Estudios</p>}
          onSelect={(e) => {
            if (e.files.length) {
              setFormState({ ...formState, file: e.files[0].objectURL });
              // console.log(e.files[0].type);
            } else {
              setFormState({ ...formState, file: null });
            }
          }}
          url={`${BASE_URL}insurance/medical-case-file/`}
          auto
          accept=".jpeg,.jpg,.png,.pdf"
          onBeforeUpload={({ formData, xhr }) => {
            formData.append("medical_case", caseId);
            formData.append("type_requirement", formState.fileType);

            const reqListener = (r) => {
              console.log("rrr", r);
              console.log(r.target.response);
              // convert the JSON string to a JSON object
              const data = JSON.parse(r.target.response);
              console.log(data);
              setFormState({ ...formState, file: data.file, fileName: data.file_name, fileType: data.type_requirement });
            };

            xhr.addEventListener("load", reqListener);
          }}
          onUpload={onUpload}
          onBeforeSend={(e) => {
            console.log("onBeforeSend", e);
          }}
          maxFileSize={5000000} // 5MB
          invalidFileSizeMessageDetail="El tamaño máximo permitido es 5MB"
        />
      </div>
      <Button
        severity="success"
        label="Confirmar"
        icon="pi pi-save"
        disabled={formState.file === null || formState.fileType === null}
        onClick={() => {
          onSave(formState);
          setFormState({ file: null, fileType: null, fileName: "" });
          onHide();
        }}
      />
    </Dialog>
  );
}
