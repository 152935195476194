import { DataTable } from "primereact/datatable";
import Dashboard from "../Layout/Dashboard";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { currencyFormat } from "../utils";
import { useState } from "react";
import { FilterMatchMode, FilterService } from "primereact/api";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import "./Advances.css";
import { InputNumber } from "primereact/inputnumber";
import { useCurrentDollarRate, useMedicalCenters } from "../hooks/queries";

/**
 * Applies currency format to the given number for the datatable
 * @param {string} key
 */
function createCurrencyTemplate(key) {
  return (row) => currencyFormat(row[key]);
}

/**
 * Show the consumed percentage with icon
 */
function consumedPercentageTemplate(row) {
  let color = "#16a34a";
  if (row.consumed >= 85) {
    color = "#dc2626";
  } else if (row.consumed >= 70) {
    color = "#f59e0b";
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        gap: "0.5rem",
        alignItems: "center",
        paddingInline: "1rem",
      }}
    >
      <ProgressBar
        value={row.consumed}
        showValue={false}
        style={{ maxHeight: "0.8rem", width: "100%" }}
        color={color}
      />
      <span>{Number(row.consumed).toFixed(2)}%</span>
    </div>
  );
}

// Register consumed filter
FilterService.register("custom_consumed", (value, filters) => {
  const [from, to] = filters ?? [null, null];
  if (from === null && to === null) return true;
  if (from !== null && to === null) return from <= Number(value);
  if (from === null && to !== null) return Number(value) <= to;
  return from <= Number(value) && Number(value) <= to;
});

/**
 * Filter for consumed percentage
 */
const consumedFilterTemplate = (options) => {
  const [from, to] = options.value ?? [null, null];

  return (
    <div className="flex gap-1">
      <InputNumber
        value={from}
        onChange={(e) => options.filterApplyCallback([e.value, to])}
        className="w-full"
        placeholder="desde"
      />
      <InputNumber
        value={to}
        onChange={(e) => options.filterApplyCallback([from, e.value])}
        className="w-full"
        placeholder="hasta"
      />
    </div>
  );
};

/**
 * Listado de centros médicos con su anticipo
 */
export default function AdvanceListing() {
  // Table filters
  const [filters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    consumed: { value: null, matchMode: FilterMatchMode.CUSTOM },
  });

  // Get medical centers
  const providersQuery = useMedicalCenters();
  const dollarRateQuery = useCurrentDollarRate();
  const dollarRate = dollarRateQuery.data?.rate ?? 0;

  // Calculate summary data
  const [totalFund, totalIncurred, totalAvailable] = providersQuery.isLoading
    ? [null, null, null]
    : [
        providersQuery.data?.reduce(
          (prev, provider) =>
            prev + Number(provider.fund ? provider.fund.amount_advance : 0),
          0
        ) ?? 0,
        providersQuery.data?.reduce(
          (prev, provider) =>
            prev + Number(provider.fund ? provider.fund.amount_incurred : 0),
          0
        ) ?? 0,
        providersQuery.data?.reduce(
          (prev, provider) =>
            prev + Number(provider.fund ? provider.fund.amount_available : 0),
          0
        ) ?? 0,
      ];
  const totalPercentageConsumed = Number(
    (totalIncurred / totalFund) * 100
  ).toFixed(2);

  // const totalAvailableDollar = function () {
  //   return currencyFormat(totalAvailable / dollarRate);
  // };

  // Detail navigation
  const navigate = useNavigate();
  function navigateToClinic(event) {
    navigate("proveedores/" + event.data.id);
  }

  return (
    <Dashboard headerTitle="Resumen de Anticipos">
      <div className="p-2 p-fluid">
        <div className="advance-summary-container">
          <div className="text-center p-card align-content-center">
            {dollarRateQuery.isLoading ? (
              <ProgressSpinner />
            ) : (
              <p className="mb-1 mt-1 text-lg font-bold	">
                {currencyFormat(dollarRate)} Bs
              </p>
            )}
            <h4 className=" mb-1 mt-1 text-500">Tasa </h4>
          </div>
          <div className="text-center p-card align-content-center">
            {providersQuery.isLoading ? (
              <ProgressSpinner />
            ) : (
              <>
                <p className="mb-1 mt-1 text-lg font-bold">
                  {currencyFormat(totalFund)} Bs
                </p>
              </>
            )}
            <h4 className="mb-1 mt-1 text-500">Total</h4>
          </div>
          <div className="text-center p-card align-content-center">
            {providersQuery.isLoading ? (
              <ProgressSpinner />
            ) : (
              <p className="mb-1 mt-1 text-lg font-bold">
                {currencyFormat(totalIncurred)} Bs
              </p>
            )}
            <h4 className="mb-1 mt-1 text-500">Incurrido</h4>
          </div>
          <div className="text-center p-card align-content-center">
            {providersQuery.isLoading ? (
              <ProgressSpinner />
            ) : (
              <>
                <p className="mb-1 mt-1 text-lg font-bold">{currencyFormat(totalAvailable)} Bs</p>
                {/* <p className="mb-1 mt-1 text-lg font-bold">
                  REF. {totalAvailableDollar()}
                </p> */}
              </>
            )}
            <h4 className="mb-1 mt-1 text-500">Disponible</h4>
          </div>
          {/* <div className="p-card align-content-center">
            {providersQuery.isLoading ? (
              <ProgressSpinner />
            ) : (
              <div className="mb-1 mt-1 text-lg font-bold	">
                {consumedPercentageTemplate({
                  consumed: totalPercentageConsumed,
                })}
              </div>
            )}
            <h4 className="mb-1 mt-1 text-500">% Consumido</h4>
          </div> */}
        </div>
        <div className="flex w-full">
          {providersQuery.isLoading ? (
            <ProgressSpinner />
          ) : (
            <div className="mb-2 mt-1 text-lg font-bold	w-full">
              {consumedPercentageTemplate({
                consumed: totalPercentageConsumed,
              })}
            </div>
          )}
        </div>
        <DataTable
          value={providersQuery.data ?? []}
          stripedRows
          showGridlines
          resizableColumns
          paginator
          alwaysShowPaginator={false}
          rows={15}
          rowsPerPageOptions={[10, 15, 25, 50]}
          selectionMode="single"
          onRowSelect={(event) => navigateToClinic(event)}
          dataKey="id"
          filters={filters}
          filterDisplay="row"
          removableSort
          emptyMessage="No se hallaron proveedores."
          loading={providersQuery.isLoading}
        >
          <Column
            field="name"
            header="Proveedor"
            sortable
            filter
            filterPlaceholder="Buscar proveedor"
            showFilterMenu={false}
          />
          <Column
            field="number_atention"
            header="Atenciones"
            sortable
          />
          <Column
            field="amount_advance"
            header="Fondo"
            sortable
            body={createCurrencyTemplate("amount_advance")}
          />
          <Column
            field="amount_incurred"
            header="Total incurrido"
            sortable
            body={createCurrencyTemplate("amount_incurred")}
          />
          <Column
            field="amount_available"
            header="Disponible"
            sortable
            body={createCurrencyTemplate("amount_available")}
          />
          <Column
            field="amount_available_ref"
            header="Disponible REF"
            sortable
            body={createCurrencyTemplate("amount_available_ref")}
          />
          <Column
            field="consumed"
            header="Consumido"
            sortable
            body={consumedPercentageTemplate}
            filter
            filterElement={consumedFilterTemplate}
            showFilterMenu={false}
          />
          <Column
            header="Acción"
            body={(row) => (
              <Link to={`proveedores/${row.id}`} className="p-button">
                <span className="p-button-icon p-button-icon-left pi pi-eye"></span>
                <span className="p-button-label">Ver anticipos</span>
              </Link>
            )}
          />
        </DataTable>
      </div>
    </Dashboard>
  );
}
