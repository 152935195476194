import { BASE_URL } from "../constants";
import { getAuthHeader } from "../utils";

/**
 * @typedef GroupScale
 * @property {number} id
 * @property {string} name
 * @property {string[]} company_client
 * @property {number} insurance
 */

/**
 * @typedef ScaleRequirement
 * @property {number} id
 * @property {number} requirement
 * @property {string} requirement_name
 * @property {string} requirement_type
 * @property {number} price
 */

/**
 * @typedef SingleScale
 * @property {number} id
 * @property {string} name
 * @property {ScaleRequirement[]} scale_requirement
 */

/**
 * Returns list of scales
 */
export async function getScales() {
  const response = await fetch(BASE_URL + "insurance/scale/", {
    headers: {
      ...getAuthHeader(),
    }
  });
  if (!response.ok) {
    throw new Error(JSON.stringify(response));
  }
  const data = await response.json();
  /**@type {GroupScale[]} */
  const results = data.results;
  return results;
}

/**
 * Returns data of single scale including associated services with their price
 * @param {number} scaleId
 */
export async function getSingleScale(scaleId) {
  try {
    const response = await fetch(BASE_URL + "insurance/scale/" + scaleId + "/", {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    /**@type {SingleScale} */
    const data = await response.json();
    return data;
  } catch (error) {
    return { type: "error", message: error.message };
  }
}

/**
 * Returns list of requirements associated by a scale with a client, can be filtered by requirementType
 * @param {number} clientId
 * @param {number} requirementType 
 */
export async function getScaleRequirements(clientId, requirementType) {
  try {
    // Validate
    if (!clientId || !requirementType) {
      throw new Error("Debe especificar el cliente");
    }

    // Add query params
    const params = new URLSearchParams();
    params.append("scale__company_client", clientId);
    params.append("requirement__type_requirement", requirementType);

    const response = await fetch(BASE_URL + "insurance/scale-requirement/?" + params.toString(), {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();

    /**@type {ScaleRequirement[]} */
    let results = data.results;
    
    // Get all data from pagination
    let nextUrl = data.next;
    while (nextUrl) {
      // If nextUrl begins with http, replace with https
      if (nextUrl.startsWith("http://")) {
        nextUrl = nextUrl.replace("http://", "https://");
      }
      
      const nextResponse = await fetch(nextUrl, {
        headers: {
          ...getAuthHeader(),
        },
      });
      if (!nextResponse.ok) {
        break;
      }
      const nextData = await nextResponse.json();
      results = results.concat(nextData.results);
      nextUrl = nextData.next;
    }

    return results;
  } catch (error) {
    console.error("Error at getScaleRequirements", error.message);
    return [];
  }
}

/**
 * Creates a new scale
 * @param {object} params
 * @param {string} params.name
 * @param {string[]} params.clients
 * @param {number | undefined} params.insurance
 */
async function addScale({ name, clients, insurance }) {
  try {
    const response = await fetch(BASE_URL + "insurance/scale/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify({
        name,
        company_client: clients.map((client) => client.name),
        insurance: insurance ?? 1,
      }),
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    return Number(data.id);
  } catch (error) {
    return { type: "error", message: error.message };
  }
}

/**
 * Associates a requirement with a price to a scale
 * @param {object} params
 * @param {number} params.scaleId
 * @param {number} params.requirementId
 * @param {number} params.price
 */
export async function addScaleRequirement({ scaleId, requirementId, price }) {
  try {
    const response = await fetch(BASE_URL + "insurance/scale-requirement/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify({
        scale: scaleId,
        requirement: requirementId,
        price,
      }),
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    return { type: "success", message: "Servicio asociado con éxito" };
  } catch (error) {
    return { type: "error", message: error.message };
  }
}

/**
 * Adds a scale and registers requirements
 * @param {object} params
 * @param {string} name
 * @param {string[]} clients
 * @param {number | undefined} insurance
 * @param {object[]} scaleRequirements
 */
export async function addScaleWithRequirements({ name, clients, insurance, scaleRequirements }) {
  try {
    // Create scale
    const scaleId = await addScale({ name, clients, insurance });
    if (scaleId.type && scaleId.type === "error") {
      return { type: "error", message: scaleId.message };
    }
    
    // Add requirements
    for (const requirement of scaleRequirements) {
      const response = await addScaleRequirement({
        scaleId,
        requirementId: requirement.requirement,
        price: requirement.price,
      });
      if (response.type === "error") {
        return response;
      }
    }
    return { type: "success", message: "Baremo creado correctamente" };
  } catch (error) {
    return { type: "error", message: error.message };
  }
}

/**
 * Applies changes to a scale (either add new requirements, or update existing ones)
 * @param {object} params
 * @param {number} scaleId
 * @param {object[]} requirements
 */
export async function editScale({ scaleId, requirements }) {
  try {
    for (const requirement of requirements) {
      // Add new requirements
      if (requirement.action === "create") {
        const response = await addScaleRequirement({
          scaleId,
          requirementId: requirement.requirement,
          price: requirement.price,
        });
        if (response.type === "error") {
          return response;
        }
      }
      // Edit existing requirements
      else if (requirement.action === "update") {
        const response = await fetch(BASE_URL + "insurance/scale-requirement/" + requirement.id + "/", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            ...getAuthHeader(),
          },
          body: JSON.stringify({
            price: requirement.price,
          }),
        });
        if (!response.ok) {
          throw new Error(JSON.stringify(response));
        }
      }
    }

    return { type: "success", message: "Cambios guardados correctamente" };
  } catch (error) {
    return { type: "error", message: error.message };
  }
}

/**
 * Update scale date (name or clients associated)
 * @param {object} params
 * @param {number} params.scaleId
 * @param {string} params.name
 * @param {string[]} params.clients
 */
export async function updateScaleData({ scaleId, name, clients }) {
  try {
    if (!scaleId) {
      throw new Error("Debe especificar el baremo a modificar");
    }

    // Update name
    const response = await fetch(`${BASE_URL}insurance/scale/${scaleId}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify({ name, company_client:clients }),
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }

    // Update clients
    console.log("@todo: update clients", clients);

    return { type: "success", message: "Datos actualizados correctamente" };

  } catch (error) {
    console.error("Error updating scale data", error);
    return { type: "error", message: error.message };
  }
}
