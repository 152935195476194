import React from "react";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import { Panel } from "primereact/panel";
import "./Dashboard.css";
import TopBar from "../TopBar/TopBar";
import { Menu } from "primereact/menu";
import { Link } from "react-router-dom";
import { ROUTE_ALLOWED_ROLES, UserRole } from "../constants";


// Rutas disponibles del dashboard con sus permisos
const items = [
  {
    label: "Casos Médicos",
    items: [
      {
        label: "Crear caso",
        icon: "pi pi-plus",
        url: "/casos/nuevo",
        user: ROUTE_ALLOWED_ROLES["/casos/nuevo"],
      },
      {
        label: "Listado de casos",
        icon: "pi pi-list-check",
        url: "/casos",
        user: ROUTE_ALLOWED_ROLES["/casos"],
      },
      {
        label: "Requerimientos médicos",
        icon: "pi pi-file-edit",
        url: "/requerimientos",
        user: ROUTE_ALLOWED_ROLES["/requerimientos"],
      },
    ],
  },
  {
    label: "Anticipos",
    items: [
      {
        label: "Crear anticipo",
        icon: "pi pi-plus",
        url: "/anticipos/nuevo",
        user: ROUTE_ALLOWED_ROLES["/anticipos/nuevo"],
      },
      {
        label: "Anticipos",
        icon: "pi pi-wallet",
        url: "/anticipos",
        user: ROUTE_ALLOWED_ROLES["/anticipos"],
      },
    ],
  },
  {
    label: "Baremos",
    items: [
      {
        label: "Listado de Baremos",
        icon: "pi pi-percentage",
        url: "/baremos",
        user: ROUTE_ALLOWED_ROLES["/baremos"],
      },
    ],
  },
  {
    label: "Proveedores",
    items: [
      {
        label: "Listado de Proveedores",
        icon: "pi pi-building-columns",
        url: "/proveedores",
        user: ROUTE_ALLOWED_ROLES["/proveedores"],
      },
    ],
  },
  {
    label: "Facturas",
    items: [
      {
        label: "Cargar factura",
        icon: "pi pi-plus",
        url: "/centro-medico/facturas/nueva",
        user: ROUTE_ALLOWED_ROLES["/centro-medico/facturas/nueva"],
      },
      {
        label: "Listado de facturas",
        icon: "pi pi-receipt",
        url: "/centro-medico/facturas",
        user: ROUTE_ALLOWED_ROLES["/centro-medico/facturas"],
      },
    ],
  },
  {
    label: "Usuarios",
    items: [
      {
        label: "Listado de usuarios",
        icon: "pi pi-users",
        url: "/usuarios",
        user: ROUTE_ALLOWED_ROLES["/usuarios"],
      },
    ],
  },
  {
    label: "Cerrar sesión",
    items: [
      {
        label: "Cerrar sesión",
        icon: "pi pi-sign-out",
        url: "/logout",
        user: ROUTE_ALLOWED_ROLES["/logout"],
      }
    ],
  },
];

/**
 * Main dashboard box
 * @param {object} props
 * @param {string} props.headerTitle
 * @param {string | undefined} props.backButtonUrl
 * @param {string | undefined} props.fixedWidth
 */
export default function Dashboard({
  headerTitle,
  backButtonUrl,
  fixedWidth,
  children,
}) {
  

  const itemsFilter = (item) => {
    const role = localStorage.getItem("role");
    if (!role) return false;
    // if (!role) return true;
    if (role === UserRole.MASTER) {
      return true;
    }
    return item.user.includes(role);
  }

  return (
    <div className="layout-dashboard">
      <TopBar></TopBar>
      <div className="layout-content">
        <div className="layout-sidebar">
          <div className="mb-4">
            <Avatar label="LM" size="xlarge" shape="circle" />
            <Badge value="8" severity="info" />
          </div>
          <Menu model={
            items
              .map((routeGroup) => ({ ...routeGroup, items: routeGroup.items.filter(itemsFilter) }))
              .filter((routeGroup) => !!routeGroup.items.length)
            }
          />
        </div>
        <div className="layout-main-container">
          <div className="layout-main">
            {backButtonUrl && (
              <Link to={backButtonUrl} className="back-link">
                <i className="pi pi-chevron-left" aria-hidden="true"></i>
                <span>Volver</span>
              </Link>
            )}
            <Panel
              header={headerTitle ?? "Dashboard"}
              style={fixedWidth ? { width: fixedWidth } : {}}
            >
              {children}
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
}
