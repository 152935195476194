import { Card } from "primereact/card";
import TemplateDemo from "../TopBar/TopBar";
import { InputText } from "primereact/inputtext";
import "./Login.css";
import { Button } from "primereact/button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authLogin } from "../services/auth";
import { useMutation } from '@tanstack/react-query';
import { getRouteForRole } from "../constants";


/**
 * User login form
 */
export default function Login() {

  // Form state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const canSubmit = username.length > 0 && password.length > 0;

  const navigate = useNavigate();

  const loginMutation = useMutation(
    {
      mutationFn: authLogin,
      onSuccess: (data) => {
        console.log('Login successful:', data);
        localStorage.setItem('token', data.token);
        localStorage.setItem('role', data.user.role);
        const route = getRouteForRole(data.user.role);
        navigate(route);
      },
      onError: (error) => {
        console.error('Error fetching login', error);
      },
    }
  );

  const handleLogin = (e) => {
    e.preventDefault();
    loginMutation.mutate({ username, password });
  };

  return (
    <>
      <TemplateDemo />
      <div className="login-container">
        <Card title="Iniciar sesión">
          <form className="p-fluid" onSubmit={handleLogin}>
            <div className="p-field">
              <label htmlFor="username">Correo</label>
              <InputText id="username" name="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className="p-field">
              <label htmlFor="password">Contraseña</label>
              <InputText id="password" name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <Button
              type="submit"
              label="Ingresar"
              disabled={loginMutation.isPending || !canSubmit}
              loading={loginMutation.isPending}
            />
          </form>
        </Card>
      </div>
    </>
  );
}
