import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { DeleteButton, EditButton } from "../components/TableButtons";
import { currencyFormat } from "../utils";
import { useState } from "react";
import { FilterMatchMode } from "primereact/api";

/**
 * DataTable for displaying a scale's requirements
 * @param {object} props
 * @param {object[]} props.value
 * @param {boolean} props.loading
 * @param {Function} props.onClickEdit
 * @param {Function} props.onClickDelete
 */
export default function BaremoRequirementsTable({ value, loading = false, onClickEdit, onClickDelete }) {

  // Table filters
  const [filters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    typeName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  /**
   * Input for requirement price for baremo
   */
  const priceBodyTemplate = (row) => `$${currencyFormat(row.price)}`;

  /**
   * Edit and delete button for rows
   */
  const actionBodyTemplate = (row) => (
    <div className="flex justify-content-center">
      <EditButton onClick={onClickEdit(row)} />
      {row.action === "create" && <DeleteButton onClick={onClickDelete(row)} /> }
    </div>
  );

  return (
    <DataTable
      stripedRows
      loading={loading}
      value={value}
      emptyMessage="No hay servicios asociados al baremo"
      dataKey="requirement"
      removableSort
      filters={filters}
      filterDisplay="row"
      scrollable scrollHeight="400px" 
      virtualScrollerOptions={{ itemSize: 30 }}
    >
      <Column
        header="Servicio"
        field="name"
        style={{ maxWidth: "280px" }}
        sortable
        filter
        showFilterMenu={false}
        filterPlaceholder="Buscar por servicio"
      />
      <Column
        header="Tipo de servicio"
        field="typeName"
        style={{ maxWidth: "280px" }}
        sortable
        filter
        showFilterMenu={false}
        filterPlaceholder="Buscar por tipo de servicio"
      />
      <Column header="Precio" field="price" sortable body={priceBodyTemplate} />
      <Column header="Acción" body={actionBodyTemplate} />
    </DataTable>
  );
}
