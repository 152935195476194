// Services for requirements
import { BASE_URL } from "../constants";
import { getAuthHeader } from "../utils";

/**
 * @typedef APIRequirementTypes
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef APIDiagnosis
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef APIRequirement
 * @property {number} id
 * @property {string} name
 * @property {number} type_requirement
 * @property {string} type_requirement_name
 */

/**
 * Get list of requirements
 * @param {number | undefined} typeId Requirement type ID
 * @param {number[]} companies List of company IDs
 */
export async function getRequirements(typeId, companies=[]) {
  try {
    const params = new URLSearchParams();
    if (companies.length > 0) {
      companies.forEach((company) => {
        params.append("companies", company);
      });
    }
    if (typeId) {
      params.append("type_requirement", typeId);
    }
    // agregar query argumentos al fetch
    const response = await fetch(BASE_URL + "insurance/requirement/?" + params.toString(), {
      headers: {
        ...getAuthHeader(),
      }
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    /**@type {APIRequirement[]} */
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching requirements: ", error.toString());
    return [];
  }
}

/**
 * Get medical centers that can attend a requirement
 * @deprecated
 * @param {number | undefined} requirementId 
 */
export async function getRequirementMedicalCenters(requirementId) {
  try {
    let query = requirementId;
    if (!requirementId) {
      query = "";
    }
    const response = await fetch(BASE_URL + "requirement-medical-center/?requirement=" + query, {
      headers: {
        ...getAuthHeader(),
      }
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error("Error fetching medical centers for requirement: ", error);
    return [];
  }
}

/**
 * Get list of requirement types
 */
export async function getRequirementTypes() {
  try {
    const response = await fetch(BASE_URL + "insurance/type-requiremet/", {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    /**@type {APIRequirementTypes[]} */
    const results = data.results;
    return results;
  } catch (error) {
    console.error("Error fetching requirement types: ", error);
    return [];
  }
}

/**
 * Get list of diagnosis
 */
export async function getDiagnosisList() {
  try {
    const response = await fetch(BASE_URL + "insurance/medical-diagnosis/", {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    
    /**@type {APIDiagnosis[]} */
    let results = data.results;

    // Get all data from pagination
    let nextUrl = data.next;
    while (nextUrl) {
      // If nextUrl begins with http, replace with https
      if (nextUrl.startsWith("http://")) {
        nextUrl = nextUrl.replace("http://", "https://");
      }
      
      const nextResponse = await fetch(nextUrl, {
        headers: {
          ...getAuthHeader(),
        },
      });
      if (!nextResponse.ok) {
        break;
      }
      const nextData = await nextResponse.json();
      results = results.concat(nextData.results);
      nextUrl = nextData.next;
    }

    return results;
  } catch (error) {
    console.error("Error fetching diagnosis list: ", error);
    return [];
  }
}

/**
 * Add a new requirement
 * @param {object} requirement 
 * @param {number} requirement.requirementType Requirement type ID
 * @param {string} requirement.name Requirement name
 */
export async function addRequirement({ requirementType, name }) {
  try {
    const response = await fetch(BASE_URL + "insurance/requirement/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify({
        type_requirement: requirementType,
        name: name,
      }),
    });
    if (response.status !== 201) {
      throw new Error(JSON.stringify(response));
    }
    return { type: "success", message: "Requerimiento agregado correctamente" };
  } catch (error) {
    console.error("Error adding requirement: ", error);
    return { type: "error", message: "Error al agregar el requerimiento" };
  }
}

/**
 * Update requirement data
 * @param {object} params
 * @param {number} params.id Requirement ID
 * @param {number} params.type Requirement type ID
 * @param {string} params.name Requirement name
 */
export async function editRequirement({ id, type, name }) {
  try {
    const response = await fetch(BASE_URL + "insurance/requirement/" + id + "/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify({
        type_requirement: type,
        name,
      }),
    });
    if (response.status !== 200) {
      throw new Error(JSON.stringify(response));
    }
    return { type: "success", message: "Requerimiento editado correctamente" };
  } catch (error) {
    console.error("Error editing requirement: ", error);
    return { type: "error", message: "Error al editar el requerimiento" };
  }
}
