import React, { useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { IconField } from "primereact/iconfield";
// import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import { currencyFormat, formatCi, formatDateString, formatFullNumber, readableSex } from "../utils";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import StatusTag from "../components/StatusTag";
import { Button } from "primereact/button";
import { GENDERS } from "../constants";
import { Paginator } from "primereact/paginator";
import Dashboard from "../Layout/Dashboard";
import { useMedicalCases, useRequirementTypes } from "../hooks/queries";

/**
 * Dropdown to filter case patient sex
 */
const sexFilterTemplate = (options) => (
  <Dropdown
    value={options.value}
    options={GENDERS}
    onChange={(e) => options.filterApplyCallback(e.value)}
    placeholder="Seleccionar sexo"
    className="p-column-filter min-w-min"
    showClear
    style={{ maxWidth: "10rem" }}
  />
);

/**
 * Template to show the amount in currency format
 */
const amountBodyTemplate = (rowData) => {
  return currencyFormat(rowData.amount ?? 0);
}

const statuses = [
  "remesado",
  "atendido",
  "pendiente",
  "conciliado",
  "auditado",
  "anulado",
];

/**
 * Status tag for filter selector
 */
const statusItemTemplate = (option) => {
  return <StatusTag value={option} />;
};

/**
 * Status filter dropdown
 */
const statusRowFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={statuses}
      onChange={(e) => options.filterApplyCallback(e.value)}
      itemTemplate={statusItemTemplate}
      placeholder="Seleccionar status"
      className="p-column-filter min-w-min"
      showClear
    />
  );
};

/**
 * Modal de confirmación para cambiar status de un caso 
 */
const ChangeStatusSingleModal = ({ rowChanging, newStatus, onHide }) => {
  if (!rowChanging) return;
  return (
    <Dialog
      header="Modificar status"
      visible={rowChanging !== null}
      style={{ maxWidth: "35rem" }}
      onHide={onHide}
      draggable={false}
    >
      <div className="p-fluid text-center">
        <p>¿Seguro que deseas cambiar el status del caso #{rowChanging.id}?</p>
        <p><b>De <StatusTag value={rowChanging.status} /> a <StatusTag value={newStatus} /></b></p>
      </div>
      <br></br>
      <div className="delete-confirm-buttons-container">
        <Button type="submit" label="Cambiar status" />
        <Button type="button" label="Cancelar" style={{ backgroundColor: "#999999", borderColor: "#999999" }} onClick={onHide} />
      </div>
    </Dialog>
  );
}

/**
 * Modal de confirmación para cambiar status de varios casos en lote
 */
const ChangeStatusMultipleModal = ({ show, selection, newStatus, onHide }) => {
  if (!selection || !newStatus) return;
  return (
    <Dialog
      header="Modificar status"
      visible={show}
      style={{ maxWidth: "35rem" }}
      onHide={onHide}
      draggable={false}
    >
      <div className="p-fluid text-center">
        <p>¿Seguro que deseas cambiar el status de {selection.length} casos seleccionados?</p>
        <p><b>Nuevo status: <StatusTag value={newStatus} /></b></p>
      </div>
      <br></br>
      <div className="delete-confirm-buttons-container">
        <Button type="submit" label="Cambiar status" />
        <Button type="button" label="Cancelar" style={{ backgroundColor: "#999999", borderColor: "#999999" }} onClick={onHide} />
      </div>
    </Dialog>
  );
}

/**
 * Medical cases table
 */
export default function MedicalCasesListing() {

  // Get medical cases
  const casesQuery = useMedicalCases();
  
  // Case detail link
  const ViewDetailBodyTemplate = (row) => (
    <Link to={String(row.id)} className="p-button mb-3 text-sm p-2">
      <span className="p-button-icon p-button-icon-left pi pi-eye"></span>
      <span className="p-button-label">Ver detalles</span>
    </Link>
  );

  // Status change
  const [rowChanging, setRowChanging] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [newStatusMultiple, setNewStatusMultiple] = useState(null);
  const [showMultipleModal, setShowMultipleModal] = useState(false);

  // Table multiple selection
  const [selectedRows, setSelectedRows] = useState(null);

  // Get requirement types
  const requirementTypesQuery = useRequirementTypes();

  // Global filters
  const [filters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.sex": { value: null, matchMode: FilterMatchMode.EQUALS },
    requirement_type_names: { value: null, matchMode: FilterMatchMode.EQUALS },
    "holder_.ci": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.ci": { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
    requirement_names: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.birthdate": { value: null, matchMode: FilterMatchMode.CONTAINS },
    diagnosis_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    medical_center_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    subsidiary_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.phone": { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  // const [globalFilterValue, setGlobalFilterValue] = useState("");

  // const onGlobalFilterChange = (e) => {
  //   const value = e.target.value;
  //   let _filters = { ...filters };

  //   _filters["global"].value = value;

  //   setFilters(_filters);
  //   setGlobalFilterValue(value);
  // };

  /**
   * Status tag for table body
   */
  const statusBodyTemplate = (rowData) => {
    return (
      <Dropdown
        value={rowData.status}
        options={statuses}
        valueTemplate={<StatusTag value={rowData.status} />}
        itemTemplate={statusItemTemplate}
        style={{ minWidth: "10rem" }}
        onChange={(e) => {
          setNewStatus(e.value);
          setRowChanging(rowData);
        }}
      />
    );
  };

  /**
   * Dropdown to filter case requirement type
   */
  const requirementTypeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      loading={requirementTypesQuery.isLoading}
      disabled={requirementTypesQuery.isLoading}
      options={requirementTypesQuery.data ?? []}
      optionValue="name"
      optionLabel="name"
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Seleccionar tipo"
      className="p-column-filter min-w-min"
      showClear
      filter
      style={{ maxWidth: "10rem" }}
    />
  );

  /**
   * Cases table header
   */
  const TableHeader = ({ newStatus, changeNewStatus, onStatusChangeClick }) => {
    return (
      <div className="full-w-header">
        <div>
          <Dropdown
            placeholder="Cambiar status en lote"
            options={statuses}
            itemTemplate={statusItemTemplate}
            valueTemplate={newStatus !== null && <span>Cambiar a: <StatusTag value={newStatus} /></span>}
            value={newStatus}
            onChange={(e) => changeNewStatus(e.value)}
          />
          <Button label="Aplicar" className="ml-3" onClick={onStatusChangeClick} />
        </div>
        {/* <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar palabra clave"
          />
        </IconField> */}
      </div>
    );
  };

  return (
    <Dashboard headerTitle="Casos">
      <div className="card" >
        <div className="p-2">
          <DataTable
            value={casesQuery.data ?? []}
            rows={10}
            dataKey="id"
            filters={filters}
            loading={casesQuery.isLoading}
            // globalFilterFields={[
            //   "name",
            //   "sex",
            //   "requirementType",
            //   "status",
            // ]}
            header={
              <TableHeader
                newStatus={newStatusMultiple}
                changeNewStatus={setNewStatusMultiple}
                onStatusChangeClick={() => setShowMultipleModal(true)}
            />}
            emptyMessage="No se hallaron casos."
            selectionMode="checkbox"
            selection={selectedRows}
            onSelectionChange={(e) => setSelectedRows(e.value)}
            stripedRows
            scrollable
            removableSort
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            />
            <Column
              field="created"
              header="Fecha"
              sortable
              filter
              filterPlaceholder="Buscar por fecha"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
            />
            <Column
              field="id"
              header="Caso #"
              sortable
              filter
              filterPlaceholder="Buscar por Nro. de Caso"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
            />
            <Column
              field="holder_.ci"
              header="CI Titular"
              filter
              filterPlaceholder="Buscar por Titular"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
              bodyClassName="readable-cell"
              body={(row) => formatCi(row.holder_.ci)}
            />
            <Column
              field="patient_.ci"
              header="CI Paciente"
              filter
              filterPlaceholder="Buscar por CI Paciente"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
              bodyClassName="readable-cell"
              body={(row) => formatCi(row.patient_.ci)}
            />
            <Column
              field="patient_.name"
              header="Nombre Paciente"
              filter
              filterPlaceholder="Buscar por Nombre"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
              bodyClassName="readable-cell"
            />
            <Column
              field="subsidiary_name"
              header="Filial"
              filter
              filterPlaceholder="Buscar por Filial"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
            />
            <Column
              field="patient_.phone"
              header="Teléfono Paciente"
              filter
              filterPlaceholder="Buscar por Teléfono"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
              bodyClassName="readable-cell"
              body={(row) => formatFullNumber(row.patient_.phone)}
            />
            <Column
              field="patient_.birthdate"
              header="Fecha de Nacimiento"
              filter
              filterPlaceholder="Buscar por Fecha"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
              body={(row) => formatDateString(row.patient_.birthdate)}
            />
            <Column
              field="patient_.sex"
              header="Sexo"
              filter
              filterElement={sexFilterTemplate}
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
              body={(row) => readableSex(row.patient_.sex)}
            />
            <Column
              field="requirement_type_names"
              header="Tipo de requerimiento"
              filter
              filterElement={requirementTypeFilterTemplate}
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
            />
            <Column
              field="requirement_names"
              header="Requerimiento"
              filter
              filterPlaceholder="Buscar por Requerimiento"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
              bodyClassName="readable-cell"
            />
            <Column
              field="amount"
              header="Monto (Bs.)"
              filter
              filterPlaceholder="Buscar por Monto"
              showFilterMenu={false}
              body={amountBodyTemplate}
              bodyClassName="readable-cell"
              sortable
            />
            <Column
              field="diagnosis_name"
              header="Diagnóstico"
              filter
              filterPlaceholder="Buscar por Diagnóstico"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
              bodyClassName="readable-cell"
            />
            <Column
              field="medical_center_name"
              header="Proveedor"
              filter
              filterPlaceholder="Buscar por Proveedor"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
              bodyClassName="readable-cell"
            />
            <Column
              field="status"
              header="Status"
              showFilterMatchModes={false}
              showClearButton={false}
              showApplyButton={false}
              body={statusBodyTemplate}
              filter
              filterElement={statusRowFilterTemplate}
              frozen
              alignFrozen="right"
            />
            <Column header="Acción" body={ViewDetailBodyTemplate} frozen alignFrozen="right" />
          </DataTable>
          <Paginator rows={10} />
          <ChangeStatusSingleModal
            rowChanging={rowChanging}
            newStatus={newStatus}
            onHide={() => {
              if (rowChanging === null) return;
              setRowChanging(null);
              setNewStatus(null);
            }}
          />
          <ChangeStatusMultipleModal
            show={showMultipleModal}
            selection={selectedRows}
            newStatus={newStatusMultiple}
            onHide={() => {
              if (!showMultipleModal) return;
              setShowMultipleModal(false);
            }}
          />
        </div>
      </div>
    </Dashboard>
  );
}
