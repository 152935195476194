import { BASE_URL } from "../constants";
import { getAuthHeader } from "../utils";

/**
 * @typedef Client
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef Subsidiary
 * @property {number} id
 * @property {string} name
 * @property {number} company_client
 */


/**
 * Gets list of insurance clients
 * @param {boolean | undefined} scaleNull
 */
export async function getClients(scaleNull) {
  try {
    // Build params
    const params = new URLSearchParams();
    if (scaleNull !== undefined) {
      const stringValue = String(scaleNull);
      if (stringValue !== "true" && stringValue !== "false") {
        throw new Error("Invalid scaleNull param");
      }
      params.append("scale__isnull", scaleNull);
    }
    // Search data
    const response = await fetch(BASE_URL + "insurance/client/?" + params.toString(), {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error("Error fetching clients");
    }

    const data = await response.json();
    /**@type {Client[]} */
    const results = data.results;
    return results;
  } catch (error) {
    console.error("Error fetching clients", error);
    return [];
  }
}

/**
 * Get list of clients that work with a specific scale
 * @param {number} scaleId
 */
export async function getClientsOfScale(scaleId) {
  try {
    if (!scaleId) {
      throw new Error("Invalid scaleId");
    }

    const params = new URLSearchParams();
    params.append("scale", scaleId);

    const response = await fetch(BASE_URL + "insurance/client/?" + params.toString(), {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error("Error fetching clients");
    }

    const data = await response.json();
    /**@type {Client[]} */
    const results = data.results;
    return results;
  } catch (error) {
    console.error("Error fetching clients", error);
    return [];
  }
}

/**
 * Get list of subsidiaries (filiales)
 */
export async function getSubsidiaries() {
  try {
    // Search data
    const response = await fetch(BASE_URL + "insurance/subsidiary/", {
      headers: {
        ...getAuthHeader(),
      }
    });
    if (!response.ok) {
      throw new Error("Error fetching subsidiaries");
    }

    const data = await response.json();
    /**@type {Subsidiary[]} */
    const results = data.results;
    return results;
  } catch (error) {
    console.error("Error fetching subsidiaries", error);
    return [];
  }
}
