// API functions for advances

import { BASE_URL } from "../constants";
import { getAuthHeader } from "../utils";

/**
 * @typedef Advance
 * @property {number} medical_center
 * @property {number} amount_dolar
 * @property {number} rate
 * @property {number} amount_bs
 * @property {string} date
 * @property {string} reference_payment
 * @property {string?} suport_payment
 * @property {number} bank_emitter
 * @property {number} bank_receiver
 */

/**
 * @typedef GetAdvance
 * @property {number} id
 * @property {number} medical_center
 * @property {string} amount_dolar
 * @property {string} amount_bs
 * @property {string} date
 * @property {string} reference_payment
 * @property {string | null} suport_payment
 * @property {string} rate
 * @property {number} bank_emitter
 * @property {number} bank_receiver
 */


/**
 * Add a new advance
 * @param {Advance} body
 */
export async function createAdvance(body) {
  try {
    const response = await fetch(BASE_URL + "insurance/advance/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify(body),
    });
    if (response.status === 201) {
      return { type: "success", message: "Anticipo guardado con éxito" };
    }
    throw new Error(JSON.stringify(response));
  } catch (error) {
    console.error("Error creating advance: ", error);
    return { type: "error", message: "Error creando anticipo" };
  }
}

/**
 * Returns the list of advances from a specific medical center
 * @param {number | string} id Medical center ID
 */
export async function getAdvancesFromMedicalCenter(id) {
  try {
    const response = await fetch(BASE_URL + "insurance/advance/?medical_center=" + id, {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      /**@type {GetAdvance[]} */
      const results = data.results;
      return results;
    }
    throw new Error(JSON.stringify(response));
  } catch (error) {
    console.error("Error getting advances: ", error);
    return [];
  }
}

/**
 * Deletes an advance by ID
 * @param {number | string} id Advance ID
 */
export async function deleteAdvance(id) {
  try {
    const response = await fetch(`${BASE_URL}insurance/advance/${id}/`, {
      method: "DELETE",
      headers: {
        ...getAuthHeader(),
      },
    });
    if (response.ok) {
      return { type: "success", message: "Anticipo eliminado con éxito" };
    }
    throw new Error(JSON.stringify(response));
  } catch (error) {
    return { type: "error", message: error.message };
  }
}
