import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import FormSubHeading from "../components/FormSubHeading";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DAYS } from "../constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useClients, useRequirementTypes, useScaleRequirements } from "../hooks/queries";
import { useState } from "react";

/**
 * Modal to add a new requirement to medical center
 * @param {object} props
 * @param {boolean} props.show
 * @param {() => void} props.onHide
 * @param {() => void} props.saveData
 * @param {import("../hooks/queries").MedicalCenterRequirement[]} props.currentCenterRequirements
 * @param {boolean} props.shouldFilterRequirements
 * @param {object} props.formState
 * @param {React.Dispatch<React.SetStateAction<object>>} props.setFormState
 */
export default function CenterRequirementModal({
  show,
  onHide,
  saveData,
  currentCenterRequirements,
  shouldFilterRequirements,
  formState,
  setFormState,
}) {

  const [requirementType, setRequirementType] = useState(null);

  const canSave =
    formState.client && formState.requirement && formState.days.length && formState.days.every((day) => day.slots && day.slots > 0);

  // Queries
  const clientsQuery = useClients();
  const requirementTypesQuery = useRequirementTypes();
  const scaleRequirementsQuery = useScaleRequirements(formState.client?.id ?? null, requirementType);

  let requirementOptions = scaleRequirementsQuery.data ?? [];
  if (shouldFilterRequirements) {
    requirementOptions = requirementOptions.filter(
      (scaleRequirement) => {
        const match = currentCenterRequirements.find((cur) => cur.requirement === scaleRequirement.requirement);
        return !match || (match && match.client !== formState.client)
      }
    )
  }

  /**
   * Handles clicking day button
   * @param {typeof DAYS[number]} day
   */
  const onDayClick = (day) => {
    // If day is already selected, remove from formstate and make button gray
    if (formState.days.find((arrDay) => arrDay.id === day.id)) {
      setFormState({
        ...formState,
        days: formState.days.filter((arrDay) => arrDay.id !== day.id),
      });
    } else {
      // Add day to form state
      setFormState({
        ...formState,
        days: [
          ...formState.days,
          { ...day, slots: "", }
        ],
      });
    }
  }

  /**
   * Input for daily slots
   * @param { typeof DAYS[number] & { slots: number | string }} row 
   */
  const dailySlotsInputBodyTemplate = (row) => (
    <InputNumber
      placeholder="Ingrese los cupos para este día"
      value={formState.days.find((day) => day.id === row.id)?.slots}
      onChange={(e) => {
        const newDays = formState.days.map((day) => {
          if (day.id === row.id) return { ...day, slots: e.value};
          return day;
        });
        setFormState({ ...formState, days: newDays });
      }}
    />
  );

  return (
    <Dialog visible={show} onHide={onHide} draggable={false} header="Nuevo servicio" style={{ width: "40vw" }}>
      <div className="p-fluid">
        <FormSubHeading title="Servicio" />

        <div className="p-field">
          <label htmlFor="clients">Cliente</label>
          <Dropdown
            value={formState.client?.id ?? null}
            onChange={(e) => setFormState({
              ...formState,
              client: clientsQuery.data.find((c) => c.id === e.value),
              company_client_name: clientsQuery.data.find((c) => c.id === e.value)?.name,
            })}
            loading={clientsQuery.isLoading}
            disabled={clientsQuery.isLoading}
            options={clientsQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccionar clientes"
            filter
          />
        </div>

        <div className="p-field">
          <label htmlFor="requirementType">Tipo de servicio</label>
          <Dropdown
            value={requirementType}
            onChange={(e) => setRequirementType(e.value)}
            loading={requirementTypesQuery.isLoading}
            disabled={requirementTypesQuery.isLoading}
            options={requirementTypesQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccionar tipo de servicio"
            filter
          />
        </div>
        
        <div className="p-field">
          <label htmlFor="requirement">Servicio</label>
          <Dropdown
            value={formState.requirement}
            onChange={(e) => {
              if (e.value) {
                const matchRequirement = scaleRequirementsQuery.data?.find((req) => Number(req.requirement) === Number(e.value));
                if (matchRequirement) {
                  setFormState({
                    ...formState,
                    requirement: e.value,
                    requirement_name: matchRequirement.requirement_name,
                    type_requirement_name: matchRequirement.requirement_type,
                    price_ref: matchRequirement.price,
                  });
                }
              }
            }}
            itemTemplate={(option) => (
              <span>{option.requirement_name} - REF: {option.price}</span>
            )}
            loading={scaleRequirementsQuery.isLoading}
            disabled={scaleRequirementsQuery.isLoading || scaleRequirementsQuery.isPending}
            options={requirementOptions}
            optionValue="requirement"
            optionLabel="requirement_name"
            placeholder="Seleccionar requerimiento"
            filter
            virtualScrollerOptions={{ itemSize: 48 }}
            emptyMessage="No hay servicios disponibles"
          />
        </div>

        <FormSubHeading title="Disponibilidad" />
        <div className="mb-3">
          <span>Seleccione los días de atención:</span>
          <div className="day-row-selector my-2">
            { DAYS.map((day) => (
              <Button
                key={day.id}
                label={day.shortLabel}
                aria-label={day.fullLabel}
                onClick={() => onDayClick(day)}
                className={formState.days.find((formDays) => formDays.id === day.id) ? "" : "btn-off"}
              />
            )) }
          </div>
          {!!formState.days.length && (
            <DataTable value={formState.days} dataKey="id">
              <Column header="Día" field="fullLabel" />
              <Column header="Cupos disponibles" body={dailySlotsInputBodyTemplate}  />
            </DataTable>
          )}
        </div>
        <Button
          type="button"
          onClick={() => {
            saveData(formState);
            setRequirementType(null);
          }}
          label="Guardar"
          disabled={!canSave}
        />
      </div>
    </Dialog>
  );
}
