/**
 * Get registered login
 * @param {object} params
 * @param {string} params.username
 * @param {string} params.password
 */
export async function authLogin({ username, password }) {
  try {
    const url = "https://api.citamed247.com/api-token-auth/" 
    // const url = "http://localhost:8002/api-token-auth/" 
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching login", error);
    return [];
  }
}

/**
 * Remove session data from local storage
 */
export async function authLogout() {
  localStorage.removeItem("token");
  localStorage.removeItem("role");
  return true;
}
