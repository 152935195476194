// Servicios para los centros médicos (proveedores)

import { BASE_URL } from "../constants";
import { getAuthHeader } from "../utils";

/**
 * @typedef MedicalCenterFund
 * @property {string} amount_advance
 * @property {string} amount_incurred
 * @property {number} amount_available
 */

/**
 * @typedef MedicalCenter
 * @property {number} id
 * @property {string} name
 * @property {string} total_advance
 * @property {string} total_incurred
 * @property {number} available
 * @property {number} consumed
 * @property {number} number_atention
 * @property {MedicalCenterFund} fund
 * @property {string} amount_advance
 * @property {string} amount_incurred
 * @property {string} amount_available
 * @property {number} amount_available_ref
 */

/**
 * @typedef CenterRequirementDay
 * @property {"lunes" | "martes" | "miércoles" | "jueves" | "viernes" | "sábado" | "domingo"} day
 * @property {number} quota
 */

/**
 * @typedef CompanyMedicalCenterRequirement
 * @property {number} id
 * @property {number} company_client
 * @property {number} requirement
 * @property {number} requirement_price_ref
 * @property {string} company_client_name
 * @property {string} requirement_name
 * @property {string} type_requirement_name
 * @property {CenterRequirementDay[]} requirement_day
 * @property {number} medical_center
 * @property {string} medical_center_name
 */


/**
 * Get list of medical centers
 */
export async function getMedicalCenters() {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}insurance/medical-center/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization":`Token ${token}`,
        },
      }
    );

    const data = await response.json();
    
    /** @type MedicalCenter[] */
    const results = data.results;
    return results;
  } catch (error) {
    console.error("Error fetching medical centers", error);
    return [];
  }
}

/**
 * Gets singular medical center
 * @param {number} centerId
 */
export async function getMedicalCenter(centerId) {
  try {
    const response = await fetch(`${BASE_URL}insurance/medical-center/${centerId}/`, {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    /**@type {MedicalCenter} */
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching medical center", error);
    return null;
  }
}

/**
 * Get list of requirements from medical center
 * @param {number} centerId
 */
export async function getRequirementsFromCenter(centerId) {
  const response = await fetch(`${BASE_URL}insurance/company-medical-center-requirement/?medical_center=${centerId}`, {
    headers: {
      ...getAuthHeader(),
    },
  });
  if (!response.ok) {
    throw new Error(JSON.stringify(response));
  }
  /**@type {CompanyMedicalCenterRequirement[]} */
  const data = await response.json();
  return data;
}

/**
 * Get requirements of a type from medical center, available for a client
 * @param {object} params
 * @param {number} params.centerId
 * @param {number} params.client
 * @param {number} params.requirementType
 */
export async function getClientRequirementsFromCenter({ centerId, client, requirementType }) {
  // Validation
  if (!centerId || !client || !requirementType) {
    return [];
  }

  // Create query parameters
  const queryParams = new URLSearchParams();
  if (centerId) {
    queryParams.append("medical_center", centerId);
  }
  if (client) {
    queryParams.append("company_client", client);
  }
  if (requirementType) {
    queryParams.append("requirement__type_requirement", requirementType);
  }

  // Fetch data
  const response = await fetch(`${BASE_URL}insurance/company-medical-center-requirement/?${queryParams.toString()}`, {
    headers: {
      ...getAuthHeader(),
    },
  });
  if (!response.ok) {
    return [];
  }
  /**@type {CompanyMedicalCenterRequirement[]} */
  const data = await response.json();
  if (!data || !Array.isArray(data)) {
    return [];
  }

  // Extract requirements and their names from data array
  const requirements = data.map((companyMedicalCenterRequirement) => ({
    id: Number(companyMedicalCenterRequirement.requirement),
    name: String(companyMedicalCenterRequirement.requirement_name),
  }));

  return requirements;
}

/**
 * Get list of medical centers that offer at least one of the given requirements
 * @param {object} params
 * @param {number[]} params.requirements
 * @param {number?} params.client
 */
export async function getCentersFromRequirements({ requirements=[], client, }) {
  try {
    // Create query params
    const params = new URLSearchParams();
    requirements.forEach((requirement) => params.append("requirement", requirement));
    if (client) {
      params.append("company_client", client);
    }

    // Fetch data
    const response = await fetch(`${BASE_URL}insurance/company-medical-center-requirement/?${params.toString()}`, {
      headers: {
        ...getAuthHeader(),
      },
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }

    /**@type {CompanyMedicalCenterRequirement[]} */
    const data = await response.json();

    // Handle empty response
    if (!data || !Array.isArray(data)) {
      return [];
    }

    /**
     * Data will be a list of `company-medical-center-requirement`s
     * 
     * Group by medical center
     * 
     * New struct will have:
     * - medical_center: ID of the medical center
     * - medical_center_name: Name of the medical center
     * @type {{ medical_center: number, medical_center_name: string}[]}
     */
    const formattedData = [];
    data.forEach((centerRequirement) => {
      const medicalCenterId = Number(centerRequirement.medical_center);
      if (!formattedData.some((center) => Number(center.medical_center) === medicalCenterId)) {
        formattedData.push({
          medical_center: medicalCenterId,
          medical_center_name: centerRequirement.medical_center_name,
        });
      }
    });

    return formattedData;
  } catch (error) {
    console.error("Error fetching medical centers from requirements", error);
    return [];
  }
}

/**
 * Add medical center
 * @param {object} params
 * @param {string} params.name
 * @param {object[]} params.requirements
 */
export async function addMedicalCenter({ name, requirements }) {
  try {
    if (!name) {
      return {
        type: "error",
        message: "Name is required",
      };
    }
    const response = await fetch(`${BASE_URL}insurance/medical-center/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify({ name }),
    });
    if (response.status === 201) {
      // Extract medical center ID
      const body = await response.json();
      const centerId = body.id;

      // Add requirements
      requirements.forEach(async (requirement) => {
        await updateMedicalCenterRequirement({ ...requirement, centerId });
      });

      return {
        type: "success",
        message: "Proveedor registrado con éxito",
      };
    }
    throw new Error(JSON.stringify(response));
  } catch (error) {
    console.error("Error adding medical center", error);
    return {
      type: "error",
      message: "Error registrando proveedor",
    }
  }
}

/**
 * Associates a requirement to medical center
 * @param {object} params 
 * @param {{ id: number, name: string }} params.client ID of clients that medical center will serve
 * @param {number} params.requirement ID of the requirement to add
 * @param {object[]} params.days Days offered
 * @param {number} centerId ID of the medical center
 */
export async function updateMedicalCenterRequirement({ client, requirement, days, centerId }) {
  try {
    // Validations
    if (!client) {
      throw new Error("Especifique el cliente que servirá");
    }

    // Format days into backend accepted structure
    const formattedDays = days.map((day) => ({ day: day.apiLabel, quota: day.slots }));

    const response = await fetch(`${BASE_URL}insurance/company-medical-center-requirement/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      body: JSON.stringify({
        company_client: client.id,
        requirement,
        requirement_day: formattedDays,
        medical_center: centerId,
      })
    });
    if (!response.ok) {
      console.error(`Failed to add requirement ${requirement} to client ${client.name}`);
    }

    return {
      type: "success",
      message: "Requerimientos asociados con éxito",
    };
  } catch (error) {
    console.error("Error updating medical center requirements", error);
    return {
      type: "error",
      message: error.message,
    };
  }
}

/**
 * Deletes a requirement from a medical center
 */
export async function deleteMedicalCenterRequirement(id) {
  // TODO
  try{
    const response = await fetch(`${BASE_URL}insurance/company-medical-center-requirement/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeader(),
      },
      
    });
    if (response.ok) {
      return { type: "success", message: "Servicio eliminado con éxito" };
    }
  } catch (error) {
    console.error("Error deleting medical center requirement", error);
  }
}